export default {
	title: 'Как работает услуга?',
	description: 'Имя вашего EOS аккаунта это публичная информация и служит однозначным идентификатором в блокчейн сети EOS, ее можно трактовать как логин. Сгенерированный приватный ключ служит кодовым словом, открывающим доступ к EOS аккаунту и позволяет выполнять исходящие операции, его можно трактовать как пароль. Для того, чтобы работать с EOS аккаунтом, например отправлять или получать транзакции вам понадобится сторонний сервис кошелек, который работает с блокчейн сетью EOS . Данный сайт не предоставляет сервисы связанные с кошельком.',

	wallet: {
		title: 'Услуга по созданию EOS аккаунта',
		description:
			'Стоимость услуги зависит от текущей загруженности в блокчейн сети и в данный момент составляет 100 рублей',
		steps: [
			'Выберите «Я хочу создать аккаунт»',
			'Укажите желаемое имя аккаунта, возможно имя будет занято и необходимо будет подобрать другое',
			'После успешного подобранного имени аккаунта нажмите "Сгенерировать ключи"',
			'Сохраните себе информацию (имя аккаунта, публичный и приватный ключ)',
			'Подтвердите согласие с Условиями договора, политикой конфиденциальности',
			'Выберите способ оплаты',
			'Оплатите услугу',
			'После оплаты услуги для Вас будет создан электронный аккаунт. Обычно это занимает несколько секунд, но предельный срок исполнения 24 часа',
			'Вы можете использовать приватный ключ для импорта в любой сервис по работе с электронными аккаунтами блокчейн сети EOS.',
		],
	},

	resource: {
		title: 'Услуга по добавлению ресурсов.',
		description:
			'Стоимость услуги напрямую зависит от количества ресурсов, которые Вы желаете добавить на свой аккаунт. Чем больше ресурсов, тем больше итоговая стоимость.',
		steps: [
			'Выберите «Я хочу добавить ресурсы».',
			'Укажите имя аккаунта для управления его ресурсами.',
			{
				title: 'Выберите ресурс',
				steps: [
					'CPU - влияет на максимально допустимое количество исходящих транзакций в сутки. Необходимо держать уровень загрузки менее 100%. Текущая степень загрузки отображается на сайте.',
					'NET - влияет на максимально допустимое количество исходящих транзакций в сутки. Необходимо держать уровень загрузки менее 100%. Текущая степень загрузки отображается на сайте.',
					'RAM - влияет на максимально допустимое количество информации, связанной с вашим аккаунтом. Необходимо иметь уровень загрузки менее 100%, чтобы иметь возможность расширить количество балансов. Текущая степень загрузки отображается на сайте.',
				],
			},
			'Укажите сумму на которую Вы желаете увеличить каждый из ресурсов.',
			'Подтвердите согласие с Условиями договора, политикой конфиденциальности.',
			'Выберите способ оплаты.',
			'Оплатите услугу.',
			'Ваши ресурсы будут пополнены, вы сможете увидеть степень их загрузки на этом сайте или в сторонних приложениях по работе с электронными аккаунтами. Обычно это занимает несколько секунд, но предельный срок исполнения 24 часа.',
		],
	},
}
