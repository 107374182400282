import React, { Fragment, useMemo } from 'react'
import { ReactComponent as IconCheck } from 'assets/icons/check.svg'
import useSettingsQuery from 'helpers/useSettingsQuery'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import FormField, { Props as FormFieldProps } from 'components/UIKit/FormField'
import Select, { Props as SelectProps } from 'components/UIKit/Select'
import { Currency, PaymentSystem } from 'apollo/__generated_types__/globalTypes'
import { useTranslation } from 'react-i18next'
import { Settings_finances } from 'apollo/__generated_types__/Settings'
const cx = classNames.bind(styles)

type Props = Omit<SelectProps, 'options' | 'value'> &
	Omit<FormFieldProps, 'value'> & {
		onClickOption: (v: Settings_finances) => void
		value: Settings_finances
	}

const CurrencyField = ({
	label,
	className,
	onClickOption,
	...props
}: Props) => {
	const finances = useSettingsQuery().data!.finances!
	const [t] = useTranslation()

	const paymentSystems = useMemo(() => {
		const paymentSystems = {} as { [key in PaymentSystem]: true }
		finances.forEach((f) => (paymentSystems[f.payment_system] = true))
		return Object.keys(paymentSystems)
	}, [finances])

	const currencies = useMemo(() => {
		return finances.filter(
			(f) => f.payment_system === props.value.payment_system
		)
	}, [finances, props.value.payment_system])

	return (
		<Fragment>
			<FormField className={className} label={t('global.paymentSystem')}>
				<Select
					{...props}
					isActive
					value={
						t('global.paymentSystem', {
							context: props.value.payment_system,
						}) as string
					}
					options={paymentSystems.map((ps) => {
						return {
							key: ps,
							element: (
								<button
									type='button'
									className={cx(
										'SelectOption',
										props.value.payment_system === ps && 'SelectOption_selected'
									)}
									onClick={() => {
										onClickOption(
											finances.filter((f) => f.payment_system === ps)[0]
										)
									}}
								>
									<IconCheck /> {t('global.paymentSystem', { context: ps })}
								</button>
							),
						}
					})}
				/>
			</FormField>
			<FormField className={className} label={label}>
				<Select
					{...props}
					options={currencies.map((finance) => {
						const selected = props.value.currency === finance.currency
						return {
							key: finance.id,
							element: (
								<button
									type='button'
									className={cx(
										'SelectOption',
										selected && 'SelectOption_selected'
									)}
									onClick={() => {
										onClickOption(finance)
									}}
								>
									<IconCheck /> {finance.currency.toUpperCase()}
								</button>
							),
						}
					})}
					value={props.value.currency}
					isActive={!!props.value}
				/>
			</FormField>
			{props.value.payment_system === PaymentSystem.piastrix && (
				<p className={cx('Hint')}>{t('global.cardHint')}</p>
			)}
		</Fragment>
	)
}

export default CurrencyField
