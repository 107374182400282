import React, { memo, Fragment } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

type StepNode =
	| string
	| {
			title: string
			steps: StepNode[]
	  }

const Step = ({ data }: { data: StepNode }) => {
	if (typeof data === 'string') {
		return <li>{data}</li>
	} else {
		return (
			<li>
				{data.title}
				<ol>
					{data.steps.map((data, i) => (
						<Step data={data} key={i} />
					))}
				</ol>
			</li>
		)
	}
}

const PageFaq = memo(() => {
	const [t] = useTranslation()
	const walletSteps: string[] = t('pageFaq.wallet.steps', {
		returnObjects: true,
	})
	const resourceSteps: StepNode[] = t('pageFaq.resource.steps', {
		returnObjects: true,
	})
	return (
		<div className={cx('Component', 'container')}>
			<article className={cx('Content')}>
				<h2 className={cx('Title')}>{t('pageFaq.title')}</h2>
				<p>{t('pageFaq.description')}</p>
				<h3>{t('pageFaq.wallet.title')}</h3>
				<p>{t('pageFaq.wallet.description')}</p>
				<ol>
					{walletSteps.map((text, i) => (
						<Step data={text} key={i} />
					))}
				</ol>
				<h3>{t('pageFaq.resource.title')}</h3>
				<p>{t('pageFaq.resource.description')}</p>
				<ol>
					{/* @todo -- refactor this... */}
					{resourceSteps.map((text, i) => (
						<Step data={text} key={i} />
					))}
				</ol>
			</article>
		</div>
	)
})

export default PageFaq
