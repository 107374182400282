import { useState } from "react"

function useModal<T>(initialState = false) {
	const [isOpen, setIsOpen] = useState<boolean>(initialState)
	const [payload, setPayload] = useState<null | T>(null)

	const open = (payload?: T) => {
		setIsOpen(true)
		setPayload(payload ?? null)
	}

	const close = () => {
		setIsOpen(false)
		setPayload(null)
	}

	return { isOpen, open, close, payload }
}

export default useModal