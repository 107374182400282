import React, { memo, Fragment, ReactNode } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg'
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg'
import Param from 'components/UIKit/Param'
import { AccountData } from '.'
import Attention from 'components/UIKit/Attention'
import Field from 'components/UIKit/Field'
import { UseFormField } from 'helpers/useFormField'
import validator from 'helpers/validator'
import CurrencyField from 'components/CurrencyField'
import Checkbox from 'components/UIKit/Checkbox'
import { Link } from 'react-router-dom'
import { Settings_finances } from 'apollo/__generated_types__/Settings'
const cx = classNames.bind(styles)

type Props = {
	username: string
	accountData: AccountData
	cpuField: UseFormField<string>
	netField: UseFormField<string>
	ramField: UseFormField<string>
	finance: UseFormField<Settings_finances>
	agree: boolean
	setAgree: (v: boolean) => void
}

const ResourceField = ({
	label,
	used,
	field,
	unit,
}: {
	label: ReactNode
	used: string
	field: UseFormField<string>
	unit: string
}) => {
	const warning = +used > 75
	const overLimit = +used >= 100
	return (
		<div className={cx('FormField')}>
			<div className={cx('Stat')}>
				{overLimit || warning ? (
					<IconWarning
						className={cx(
							'StatIconWarning',
							warning && 'StatIconWarning_warning'
						)}
					/>
				) : (
					<IconSuccess className={cx('StatIconSuccess')} />
				)}
				<div
					className={cx(
						'StatDetails',
						warning && 'StatDetails_warning',
						overLimit && 'StatDetails_limit'
					)}
				>
					<span>{label}</span>
					{used}%
				</div>
			</div>

			<Field
				classNameWrapper={cx('Field')}
				value={field.value}
				placeholder='0.00'
				isActive={!!field.value}
				onChange={(e) => field.change(validator(e, field.value))}
				onBlur={() =>
					field.change(field.value && Number(field.value).toString())
				}
				pattern='^\d{1,8}(\.\d{0,2})?$'
				rightControl={<span className={cx('Unit')}>{unit}</span>}
			/>
		</div>
	)
}

const PaymentStep = memo(
	({
		username,
		accountData,
		cpuField,
		netField,
		ramField,
		finance,
		agree,
		setAgree,
	}: Props) => {
		const [t] = useTranslation()

		const cpu = (accountData.cpu_limit.max
			? accountData.cpu_limit.used / accountData.cpu_limit.max
			: accountData.cpu_limit.max
		).toFixed(2)
		const net = (accountData.net_limit.max
			? accountData.net_limit.used / accountData.net_limit.max
			: accountData.net_limit.max ?? 0
		).toFixed(2)
		const ram = (accountData.ram_quota
			? accountData.ram_usage / accountData.ram_quota
			: accountData.ram_quota ?? 0
		).toFixed(2)

		return (
			<Fragment>
				<Attention>{t('pageResource.paymentStep.attention')}</Attention>

				<Param
					label={t('pageResource.paymentStep.params.account')}
					value={username}
				/>

				<ResourceField label='CPU' used={cpu} field={cpuField} unit='EOS' />
				<ResourceField label='NET' used={net} field={netField} unit='EOS' />
				<ResourceField label='RAM' used={ram} field={ramField} unit='EOS' />

				<CurrencyField
					label={t('pageResource.paymentStep.currency.label')}
					placeholder={t('pageResource.paymentStep.currency.placeholder')}
					value={finance.value}
					onClickOption={finance.change}
				/>

				<div className={cx('AgreeGroup')}>
					<Checkbox
						label={
							<Trans i18nKey='pageResource.paymentStep.agree'>
								<Link
									to='/terms'
									target='_blank'
									onClick={(e) => e.stopPropagation()}
								>
									.
								</Link>
							</Trans>
						}
						checked={agree}
						setChecked={setAgree}
					/>
				</div>
			</Fragment>
		)
	}
)

export default PaymentStep
