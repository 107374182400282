export default {
	title: 'Сделка #{{ orderId }}',

	status: {
		EOS_ERROR: 'Оплата получена. Исполняем заказ',
		EOS_WAITING: 'Оплата получена. Исполняем заказ',
		EXPIRED: 'Срок действия заказа истек',
		FINISHED_CREATE: 'Заказ успешно выполнен! Теперь вы можете использовать ваш {{username}} аккаунт в любом электронном сервисе по работе с аккаунтами EOS',
		FINISHED_RESOURCE: 'Заказ успешно выполнен! Теперь вы можете проверить ресурсы вашего аккаунта {{username}}',
		PAYMENT_REFUSED: 'Оплата не получена',
		PAYMENT_SUCCESS: 'Оплата получена. Исполняем заказ',
		WAITING: 'Ожидание оплаты',
	},
    hint: "Пожалуйста обновите страницу, чтобы проверить текущий статус выполнения заявки",

	actions: {
		check: 'Проверить',
		tryAgain: 'Попробовать снова',
	},

	username: 'Имя аккаунта',
	publicKey: 'Публичный ключ',
	orderType: 'Тип заявки',
	transactionId: 'ID транзакции',
	price: 'Стоимость',
	currency: 'Валюта',

	walletLinks:
		'Вы можете начать работу с аккаунтом используя бесплатный кошелек <0>{{link1}}</0> или <1>{{link2}}</1>',
}
