/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CreateOrderErrorCode {
  BROKEN_FINANCE = "BROKEN_FINANCE",
  CREATING_ERROR = "CREATING_ERROR",
  INVALID_PUB_KEY = "INVALID_PUB_KEY",
  INVALID_RESOURCE = "INVALID_RESOURCE",
  INVALID_USERNAME = "INVALID_USERNAME",
  NOT_ENOUGH_BALANCE = "NOT_ENOUGH_BALANCE",
}

export enum Currency {
  EUR = "EUR",
  RUB = "RUB",
  RUBCASH = "RUBCASH",
  UAHCASH = "UAHCASH",
  USD = "USD",
  USDCASH = "USDCASH",
}

export enum OrderStatus {
  EOS_ERROR = "EOS_ERROR",
  EOS_WAITING = "EOS_WAITING",
  EXPIRED = "EXPIRED",
  FINISHED = "FINISHED",
  PAYMENT_REFUSED = "PAYMENT_REFUSED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  WAITING = "WAITING",
}

export enum OrderType {
  CREATE = "CREATE",
  RESOURCE = "RESOURCE",
}

export enum PaymentSystem {
  pc4store = "pc4store",
  piastrix = "piastrix",
}

export interface CreateOrderInput {
  financeId: string;
  username: string;
  orderType: OrderType;
  publicKey?: string | null;
  ram?: string | null;
  cpu?: string | null;
  net?: string | null;
}

export interface GetOrderPriceInput {
  financeId: string;
  orderType: OrderType;
  ram?: string | null;
  cpu?: string | null;
  net?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
