export default {
	title: 'Account generator',
	disabled: 'Service is unavailable for technical reasons.\nPlease try later',
	back: 'BACK',
	usernameStep: {
		title: 'Choose account name',
		label: 'Account name',
		generateBtn: 'I’m feeling lucky',
		rules: {
			length: 'required 12 symbols',
			pattern: 'only english lower case symbols (a - z) and digits 1-5',
			isFree_true: 'account name is free',
			isFree_false: 'account name already exists',
		},
		actions: {
			validate: 'Validate account name',
			generate: 'Generate keys',
			generateError: 'ERROR! Generate again',
		},
	},

	paymentStep: {
		title: 'data confirmation',
		attention:
			'Securely store your private key on a personal computer, phone or write down on a piece of paper. If it is lost or stolen, access to funds in your account will be lost without the possibility of recovery. You need this key to import into the electronic services that work with EOS accounts.',
		params: {
			account: 'Account name',
			publicKey: 'Public key',
			privateKey: 'Private key',
		},

		agreeSave: 'I saved data',
		agree: 'I agree with <0>Terms</0>',

		currency: {
			label: 'Choose payment currency',
			placeholder: 'Payment currency',
		},
		actions: {
			choose: 'Choose currency',
			pay: 'Pay {{ amount }}',
		},
	},
}
