import { useQuery, useSubscription } from '@apollo/react-hooks'
import ORDER from 'apollo/queries/ORDER.graphql'
import ORDER_DETAILS from 'apollo/subscriptions/ORDER_DETAILS.graphql'
import { OrderStatus, OrderType } from 'apollo/__generated_types__/globalTypes'
import { Order, OrderVariables } from 'apollo/__generated_types__/Order'
import {
	OrderDetails,
	OrderDetailsVariables,
} from 'apollo/__generated_types__/OrderDetails'
import { ReactComponent as IconCancel } from 'assets/icons/cancel.svg'
import { ReactComponent as IconClock } from 'assets/icons/clock.svg'
import { ReactComponent as IconShield } from 'assets/icons/shield.svg'
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg'
import classNames from 'classnames/bind'
import Section from 'components/Section'
import Button from 'components/UIKit/Button'
import { URL_MAP } from 'containers/App'
import React, { Fragment, memo, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import styles from './index.module.scss'
const cx = classNames.bind(styles)

const Param = ({ value, label }: { value?: ReactNode; label: ReactNode }) => {
	return (
		<div className={cx('Param')}>
			<b className={cx('ParamLabel')}>{label}:</b>
			<span className={cx('ParamValue')}>{value ?? '-'}</span>
		</div>
	)
}

const PageOrder = memo(
	({
		history,
		match: {
			params: { id },
		},
	}: RouteComponentProps<{ id: string }>) => {
		const [t] = useTranslation()
		const orderQuery = useQuery<Order, OrderVariables>(ORDER, {
			variables: { id },
		})

		useSubscription<OrderDetails, OrderDetailsVariables>(ORDER_DETAILS, {
			variables: {
				id,
			},
		})

		const order = orderQuery.data?.order
		const status = order?.status

		const onClickTryAgain = () => {
			const isCreating = order?.orderType === OrderType.CREATE
			history.push({
				pathname: isCreating ? URL_MAP.generator : URL_MAP.resource,
				state: isCreating
					? {
							username: order?.username ?? '',
					  }
					: {
							username: order?.username ?? '',
							cpu: order?.cpu,
							net: order?.net,
							ram: order?.ram,
							currency: order?.finance?.currency,
					  },
			})
		}

		const Icon = () => {
			switch (status) {
				case OrderStatus.FINISHED:
					return <IconSuccess />
				case OrderStatus.EXPIRED:
				case OrderStatus.PAYMENT_REFUSED:
					return <IconCancel />
				default:
					return <IconClock />
			}
		}

		const hasFinalStatus = [
			OrderStatus.FINISHED,
			OrderStatus.EXPIRED,
			OrderStatus.PAYMENT_REFUSED,
		].includes(status!)

		return (
			<div className={cx('Component', 'container_fluid')}>
				<Section>
					{orderQuery.loading ? (
						<div className={cx('Loader')}>
							<img src='/loader_green.gif' height={24} alt='' />
						</div>
					) : (
						<Fragment>
							<div className={cx('Details')}>
								<Icon />

								<p className={cx('Message')}>
									{t(`pageOrder.status.${status}`, {
										username: order?.username,
										context:
											status === OrderStatus.FINISHED
												? order?.orderType
												: undefined,
									})}
								</p>
							</div>

							{!hasFinalStatus && (
								<p className={cx('Hint')}>{t('pageOrder.hint')}</p>
							)}

							<div className={cx('ParamList')}>
								<Param
									label={t('pageOrder.orderType')}
									value={
										order?.orderType === OrderType.CREATE
											? t('pageGenerateWallet.title')
											: t('pageResource.title')
									}
								/>
								<Param
									label={t('pageOrder.username')}
									value={order?.username}
								/>

								{order?.orderType === OrderType.RESOURCE && (
									<Fragment>
										<Param label='CPU' value={`${order?.cpu ?? '0'} EOS`} />
										<Param label='NET' value={`${order?.net ?? '0'} EOS`} />
										<Param label='RAM' value={`${order?.ram ?? '0'} EOS`} />
									</Fragment>
								)}

								<Param
									label={t('pageOrder.price')}
									value={`${order?.price} ${order?.finance?.currency}`}
								/>

								{(status === OrderStatus.FINISHED ||
									status === OrderStatus.EOS_WAITING) &&
									!!order?.txnId && (
										<Fragment>
											<Param
												label={t('pageOrder.transactionId')}
												value={
													<a
														target='_blank'
														rel='noopener noreferrer'
														href={`https://bloks.io/transaction/${order?.txnId}?tab=raw`}
														className={cx('TransactionIdLink', 'fz-4')}
													>
														<IconShield />
														{t('pageOrder.actions.check')}
													</a>
												}
											/>
										</Fragment>
									)}
							</div>

							{status === OrderStatus.FINISHED && (
								<p className={cx('WalletLinks')}>
									<Trans
										values={{ link1: 'TokenPocket', link2: 'Scatter' }}
										i18nKey='pageOrder.walletLinks'
									>
										<a
											target='_blank'
											rel='noopener noreferrer'
											href='https://www.tokenpocket.pro/'
										>
											.
										</a>
										<a
											target='_blank'
											rel='noopener noreferrer'
											href='https://get-scatter.com/'
										>
											.
										</a>
									</Trans>
								</p>
							)}

							{(status === OrderStatus.EXPIRED ||
								status === OrderStatus.PAYMENT_REFUSED) && (
								<Button
									color='red'
									tag='button'
									type='button'
									onClick={onClickTryAgain}
								>
									{t('pageOrder.actions.tryAgain')}
								</Button>
							)}
						</Fragment>
					)}
				</Section>
			</div>
		)
	}
)

export default PageOrder
