import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ru from './ru'
import en from './en'
import {
	LANGUAGES,
	LOCAL_STORAGE_LNG_KEY,
	DEFAULT_PLATFORM_LNG,
	Languages,
} from 'constants/'

const resources = {
	en: {
		translation: en,
	},
	ru: {
		translation: ru,
	},
}
const getDefaultLng = () => {
	// disable real checking of language -- use only default lang
	let savedLng = window.localStorage.getItem(
		LOCAL_STORAGE_LNG_KEY
	) as Languages | null
	const browserLng = ((window.navigator.language ||
		(window.navigator as any).userLanguage) as string)
		.slice(0, 2)
		.toLowerCase()

	if (savedLng && !LANGUAGES.includes(savedLng)) {
		window.localStorage.setItem(LOCAL_STORAGE_LNG_KEY, DEFAULT_PLATFORM_LNG)
		savedLng = DEFAULT_PLATFORM_LNG
	}

	return (
		savedLng ||
		(LANGUAGES.includes(browserLng) ? browserLng : DEFAULT_PLATFORM_LNG)
	)
}

i18n.use(initReactI18next).init({
	resources,
	lng: getDefaultLng(),
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
