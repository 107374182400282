export default {
	title: 'Создание аккаунта',
	disabled:
		'Сервис недоступен по техническим причинам.\nПожалуйста, попробуйте еще раз позже',
	back: 'НАЗАД',
	usernameStep: {
		title: 'Выберите имя аккаунта',
		label: 'Имя аккаунта',
		generateBtn: 'Мне повезет',
		rules: {
			length: 'обязательная длина 12 символов',
			pattern: 'допустимы английские буквы (a - z) и цифры от 1 до 5',
			isFree_true: 'имя аккаунта свободно',
			isFree_false: 'имя аккаунта уже существует',
		},
		actions: {
			validate: 'Подтвердить имя аккаунта',
			generate: 'Сгенерировать ключи',
			generateError: 'Ошибка! Повторите генерацию',
		},
	},

	paymentStep: {
		title: 'подтверждение данных',
		attention:
			'Надежно сохраните приватный ключ на персональном компьютере, телефоне или запишите на лист бумаги. В случае его утраты или кражи, доступ к средствам на вашем аккаунте будет потерян без возможности восстановления. Данный ключ понадобится для импорта в сервис по работе с электронными аккаунтами EOS.',

		params: {
			account: 'Имя аккаунта',
			publicKey: 'Публичный ключ',
			privateKey: 'Приватный ключ',
		},

		agreeSave: 'Я сохранил информацию',
		agree: 'Я согласен с <0>Условиями пользования</0>',

		currency: {
			label: 'Выберите валюту для оплаты',
			placeholder: 'Валюта платежа',
		},
		actions: {
			choose: 'Выберите валюту',
			pay: 'Оплатите {{ amount }}',
		},
	},
}
