import React, { memo, Fragment } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

const PagePrivacy = memo(() => {
	const [t, { language }] = useTranslation()
	return (
		<div className={cx('Component', 'container')}>
			<article className={cx('Content')}>
				<h2 className={cx('Title')}>{t('pagePrivacy.title')}</h2>

				{language === 'en' && (
					<Fragment>
						<p>
							Company IN-CTO HOLDINGS PTE. LTD. registered under number
							201710457E at 9 Raffles Place, #26-01 Republic Plaza, Singapore,
							(048619), while conducting activities through the online store "
							<a href='https://myeoswallet.net' target='_blank'>
								https://myeoswallet.net
							</a>
							" (hereinafter referred to as the "Store") in full volume complies
							with the rules for protecting personal data from unauthorized
							access by third parties, ensures the confidentiality and security
							of the personal data received.
						</p>
						<p>
							Personal data refers to any information relating to a directly or
							indirectly determined or determined individual, and personal data
							processing refers to any action (operation) or a combination of
							actions (operations) performed with or without automation of this
							funds with personal data, including collection, recording,
							systematization, accumulation, storage, specification (updating),
							extraction, use, transfer (distribution, provision, access),
							depersonalization, blocking, deletion, destruction of personal
							data.
						</p>
						<p>
							The Store does not change or edit any information about the
							Customer while placing an order on the Store's website. After
							completing the checkout process on the Store’s website, data on
							the order and the Customer are recorded in the Contractor’s
							database.
						</p>
						<p>
							The Store processes personal data solely for the purpose of
							fulfilling its contractual obligations to the Customer. The
							consent of the subject of personal data to the processing of
							personal data is not required if the processing of personal data
							is necessary for the execution of the contract to which the
							subject of personal data is a party.
						</p>
						<p>
							The Store undertakes not to disclose personal data received from
							the Customer. It is not considered a violation of the
							confidentiality of personal data when the Store provides
							information to agents and third parties acting on the basis of an
							agreement with the Store to fulfill obligations to the Customer.
						</p>
						<p>
							Information about site visitors (IP address, domain name, browser
							type and operating system, date and time of visit, etc.) is
							collected and stored for the purpose of maintaining statistics on
							visits to evaluate and analyze the site. This information is
							publicly available, so the Store is not responsible for it’s
							disclosure.
						</p>
						<p>
							The Store takes the necessary and sufficient organizational and
							technical measures to protect Customers' personal data from
							unlawful or accidental access, destruction, alteration, blocking,
							copying, distribution, as well as from other wrongful actions of
							third parties with it in accordance with the requirements
							established by the Law.
						</p>
						<p>
							The Store has the right to amend this Privacy Policy. When making
							changes in the current edition, the date of the last update is
							indicated. The new version of the Policy shall enter into force
							upon the moment it is posted at{' '}
							<a href='https://myeoswallet.net/privacy' target='_blank'>
								https://myeoswallet.net/privacy
							</a>
							, unless otherwise provided by the new version of the Policy.
						</p>
					</Fragment>
				)}

				{language === 'ru' && (
					<Fragment>
						<p>
							Компания IN-CTO HOLDINGS PTE. LTD., регистрационный номер
							201710457E, зарегистрированная по адресу 9 Raffles Place, #26-01
							Republic Plaza, Singapore, (048619), при ведении деятельности
							через интернет-магазин «
							<a href='https://myeoswallet.net' target='_blank'>
								https://myeoswallet.net
							</a>
							» (далее «Магазин») в полном объеме соблюдает правила защиты
							персональных данных от несанкционированного доступа третьих лиц.
						</p>
						<p>
							Под персональными данными понимается любая информация, относящаяся
							к прямо или косвенно определенному или определяемому физическому
							лицу, а под обработкой персональных данных — любое действие
							(операция) или совокупность действий (операций), совершаемых с
							использованием средств автоматизации или без использования таких
							средств с персональными данными, включая сбор, запись,
							систематизацию, накопление, хранение, уточнение (обновление,
							изменение), извлечение, использование, передачу (распространение,
							предоставление, доступ), обезличивание, блокирование, удаление,
							уничтожение персональных данных.
						</p>
						<p>
							Магазин не изменяет и не редактирует информацию о Заказчике при
							оформлении заказа на сайте Магазина. После завершения процесса
							оформления заказа на сайте Магазина, данные о заказе и Заказчике
							регистрируются в базе данных Исполнителя.
						</p>
						<p>
							Магазин осуществляет обработку персональных данных исключительно с
							целью выполнения своих договорных обязательств перед Заказчиком.
						</p>
						<p>
							Магазин обязуется не разглашать персональные данные, полученные от
							Заказчика. Не считается нарушением конфиденциальности персональных
							данных предоставление Магазином информации агентам и третьим
							лицам, действующим на основании договора с Магазином, для
							исполнения обязательств перед Заказчиком.
						</p>
						<p>
							Информация о посетителях сайта (IP-адрес, имя домена, тип браузера
							и операционная система, дата и время посещения и т. п.) собирается
							и сохраняется в целях ведения статистики посещений для оценки и
							анализа работы сайта. Эта информация является общедоступной, таким
							образом, Магазин не несет ответственности за ее разглашение.
						</p>
						<p>
							Магазин принимает необходимые и достаточные организационные и
							технические меры для защиты персональной информации Заказчиков от
							неправомерного или случайного доступа, уничтожения, изменения,
							блокирования, копирования, распространения, а также от иных
							неправомерных действий с ней третьих лиц в соответствии с
							требованиями, установленными Законом.
						</p>
						<p>
							Магазин имеет право вносить изменения в настоящую Политику
							конфиденциальности. При внесении изменений в актуальной редакции
							указывается дата последнего обновления. Новая редакция Политики
							вступает в силу с момента ее размещения по адресу «
							<a href='https://myeoswallet.net/privacy' target='_blank'>
								https://myeoswallet.net/privacy
							</a>
							», если иное не предусмотрено новой редакцией Политики.
						</p>
					</Fragment>
				)}
			</article>
		</div>
	)
})

export default PagePrivacy
