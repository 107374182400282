import React, { memo, PropsWithChildren, ReactNode } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

export type Props = {
	className?: string
	label?: ReactNode
}

const FormField = memo(
	({ label, className = '', children }: PropsWithChildren<Props>) => {
		return (
			<div className={cx('FormField', className)}>
				{label && <label className={cx('FormFieldLabel')}>{label}</label>}
				{children}
			</div>
		)
	}
)

export default FormField
