import error from './error'
import pageGenerateWallet from './pageGenerateWallet'
import pageResource from './pageResource'
import pageHome from './pageHome'
import pagePrivacy from './pagePrivacy'
import pageTerms from './pageTerms'
import pageCompany from './pageCompany'
import pageOrder from './pageOrder'
import pageFaq from './pageFaq'
import pageError from './pageError'
import pageUnsupported from './pageUnsupported'
import versionController from './versionController'
import modalCopyAgreement from './modalCopyAgreement'

export default {
	error,
	pageGenerateWallet,
	pageResource,
	pageHome,
	pagePrivacy,
	pageTerms,
	pageCompany,
	pageFaq,
	pageOrder,
	pageError,
	versionController,
	pageUnsupported,
	modalCopyAgreement,

	footer: {
		company: 'Компания',
		privacy: 'Конфиденциальность',
		terms: 'Условия использования',
	},

	global: {
		cardHint:
			'Для карт MasterCard принимаются оплаты в RUB, USD, EUR. Для карт Visa принимаются оплаты только в RUB. Для карт выпущенных в РФ принимаются оплаты только в RUB',
		copied: 'Скопировано!',

		paymentSystem: 'Метод оплаты',
		paymentSystem_piastrix: 'Банковской картой',
		paymentSystem_pc4store: 'PC4Store',
	},
}
