import React, { memo, useState, useEffect, FormEvent } from 'react'
import UsernameStep from './UsernameStep'
import PaymentStep from './PaymentStep'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Button from 'components/UIKit/Button'
import Steps from 'components/Steps'
import useSettingsQuery from 'helpers/useSettingsQuery'
import FormDisabled from 'components/FormDisabled'
import Section from 'components/Section'
import { useFormField } from 'helpers/useFormField'
import { useQuery, useMutation } from '@apollo/react-hooks'
import GET_ORDER_PRICE from 'apollo/queries/GET_ORDER_PRICE.graphql'
import CREATE_ORDER from 'apollo/mutations/CREATE_ORDER.graphql'
import getErrorData from 'helpers/getErrorData'
import { RouteComponentProps } from 'react-router-dom'
import {
	Currency,
	OrderType,
	CreateOrderErrorCode,
} from 'apollo/__generated_types__/globalTypes'
import {
	GetOrderPriceVariables,
	GetOrderPrice,
} from 'apollo/__generated_types__/GetOrderPrice'
import { setUser } from '@sentry/browser'
import {
	CreateOrder,
	CreateOrderVariables,
} from 'apollo/__generated_types__/CreateOrder'
const cx = classNames.bind(styles)

export type Wallet = null | {
	privateKey: string
	publicKey: string
}

enum Step {
	usernameStep,
	paymentStep,
}

export type AccountData = {
	account_name: string
	ram_quota: number
	ram_usage: number
	net_limit: { used: number; available: number; max: number }
	cpu_limit: { used: number; available: number; max: number }
}

const useCheckUsername = (username: string, onUsernameExists: () => void) => {
	const [data, setData] = useState<null | false | AccountData>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<any>(undefined)
	const { endpoint } = useSettingsQuery().data!.setting!

	useEffect(() => {
		setData(null)
	}, [username])

	const check = async (account_name = username) => {
		setLoading(true)
		setError(undefined)
		setData(null)

		await fetch(`${endpoint}/v2/state/get_account?account=${account_name}`, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.statusCode === 500) {
					setData(false)
				} else {
					setData(data.account)
					onUsernameExists()
				}
			})
			.catch((e) => {
				setData(false)
				setError(e)
			})
		setLoading(false)
	}

	return {
		isFoundUsername: data === null ? null : !!data,
		data,
		loading,
		error,
		check,
	}
}

const StepNumbers = Object.values(Step).filter((k) => !isNaN(+k)) as number[]

const useGetOrderPrice = (options: {
	financeId: string
	cpu?: string
	net?: string
	ram?: string
}) => {
	const [waiting, setWaiting] = useState(false)
	const [price, setPrice] = useState('')
	const calcResourcePriceQuery = useQuery<
		GetOrderPrice,
		GetOrderPriceVariables
	>(GET_ORDER_PRICE, {
		skip: true,
	})

	useEffect(() => {
		setWaiting(true)
		setPrice('')

		const tid = setTimeout(async () => {
			try {
				await calcResourcePriceQuery
					.refetch({
						input: {
							...options,
							orderType: OrderType.RESOURCE,
						},
					})
					.then(({ data }) => {
						setPrice(data.getOrderPrice ?? '0')
					})
					.catch(console.error)
			} finally {
				setWaiting(false)
			}
		}, 500)

		return () => {
			clearTimeout(tid)
		}
	}, [options.financeId, options.cpu, options.net, options.ram])

	return {
		loading: calcResourcePriceQuery.loading || waiting,
		price,
	}
}

const PageResource = memo(
	({
		history,
		location,
	}: RouteComponentProps<
		any,
		any,
		{
			username: string
			cpu: string
			net: string
			ram: string
			financeId: string
		}
	>) => {
		const [t] = useTranslation()
		const [username, setUserName] = useState('')
		const [step, setStep] = useState(Step.usernameStep)
		const finances = useSettingsQuery().data!.finances!

		const onUsernameExists = () => {
			setStep(Step.paymentStep)
		}

		const checkUsername = useCheckUsername(username, onUsernameExists)
		const [errorMessage, setErrorMessage] = useState('')

		const cpuField = useFormField('')
		const netField = useFormField('')
		const ramField = useFormField('')
		const finance = useFormField(finances[0])
		const [agree, setAgree] = useState(false)
		const orderPrice = useGetOrderPrice({
			financeId: finance.value.id,
			cpu: cpuField.value,
			net: netField.value,
			ram: ramField.value,
		})

		useEffect(() => {
			if (location.state?.username) {
				setUserName(location.state.username)
				checkUsername.check(location.state?.username)
				cpuField.change(location.state.cpu ?? '')
				netField.change(location.state.net ?? '')
				ramField.change(location.state.ram ?? '')
				finance.change(
					location.state.financeId
						? finances.find((f) => f.id === location.state.financeId) ||
								finances[0]
						: finances[0]
				)
			}
		}, [])

		useEffect(() => {
			setAgree(false)
			setErrorMessage('')
		}, [step])

		const [createOrderMutation, { loading: lodaingCreateOrder }] = useMutation<
			CreateOrder,
			CreateOrderVariables
		>(CREATE_ORDER)

		const isUserStep = step === Step.usernameStep
		const isPaymentStep = step === Step.paymentStep

		const hasResource =
			Number(cpuField.value) !== 0 ||
			Number(netField.value) !== 0 ||
			Number(ramField.value) !== 0
		const disabled =
			(isUserStep && (!username || checkUsername.isFoundUsername === false)) ||
			(isPaymentStep && (!hasResource || !finance.value.currency || !agree))

		const loading =
			checkUsername.loading || orderPrice.loading || lodaingCreateOrder
		const isDisabledFlow = !useSettingsQuery().data!.setting!.isPaymentAllowed

		useEffect(() => {
			setErrorMessage('')
		}, [
			step,
			cpuField.value,
			netField.value,
			ramField.value,
			finance.value.currency,
		])

		const onErrorMessage = (message = '') => {
			setErrorMessage(
				`${t('error.globalError')}${message ? ` : ${message}` : ''}`
			)
		}

		const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			setErrorMessage('')
			if (loading) return

			if (isUserStep) {
				/* need to validate account and store its data */
				if (checkUsername.isFoundUsername === null) {
					checkUsername.check()
				} else {
					onUsernameExists()
				}
			} else if (step === Step.paymentStep) {
				createOrderMutation({
					variables: {
						input: {
							orderType: OrderType.RESOURCE,
							username,
							financeId: finance.value.id!,
							cpu: cpuField.value || undefined,
							net: netField.value || undefined,
							ram: ramField.value || undefined,
						},
					},
				})
					.then(({ data }) => {
						if (
							!data?.createOrder ||
							(!data.createOrder.paymentUrl && !data.createOrder.errors?.length)
						)
							return onErrorMessage()
						const { paymentUrl, errors } = data.createOrder
						if (errors?.length) {
							const error = errors[0]
							if (error.code === CreateOrderErrorCode.NOT_ENOUGH_BALANCE) {
								setErrorMessage(t('error.notEnoughBalance_RESOURCE'))
							} else if (error.code === CreateOrderErrorCode.INVALID_USERNAME) {
								setErrorMessage(t('error.invalidUsername_RESOURCE'))
							} /* if (error.code === CreateOrderErrorCode.CREATING_ERROR)  */ else {
								onErrorMessage(error.message)
							}
							return
						}

						window.location.assign(paymentUrl ?? '')
					})
					.catch((e) => {
						onErrorMessage(getErrorData(e).message)
					})
			}
		}

		return (
			<div className={cx('Component', 'container_fluid')}>
				<Section
					afterContent={
						!isUserStep && (
							<Button
								color='link'
								type='button'
								onClick={() => setStep(Step.usernameStep)}
								className={cx('BtnBack')}
							>
								{t('pageResource.back')}
							</Button>
						)
					}
					beforeContent={
						<Steps color='blue' currentStep={step} stepList={StepNumbers} />
					}
					header={t(`pageResource.${Step[step]}.title`)}
				>
					{isDisabledFlow && (
						<FormDisabled>{t('pageResource.disabled')}</FormDisabled>
					)}
					{!isDisabledFlow && (
						<form onSubmit={onSubmit}>
							{step === Step.usernameStep && (
								<UsernameStep
									username={username}
									setUserName={setUserName}
									isFound={checkUsername.isFoundUsername}
								/>
							)}
							{step === Step.paymentStep && (
								<PaymentStep
									cpuField={cpuField}
									netField={netField}
									ramField={ramField}
									finance={finance}
									username={username}
									accountData={checkUsername.data as AccountData}
									agree={agree}
									setAgree={setAgree}
								/>
							)}

							<p className={cx('ErrorMessage')}>{errorMessage}</p>

							<Button
								className={cx(loading && 'BtnSubmit_loading')}
								color='blue'
								disabled={disabled}
								type='submit'
							>
								{loading ? (
									<img src='/loader_blue.gif' height={24} alt='' />
								) : isUserStep ? (
									checkUsername.isFoundUsername ? (
										t('pageResource.usernameStep.actions.submit')
									) : (
										t('pageResource.usernameStep.actions.validate')
									)
								) : hasResource ? (
									t('pageResource.paymentStep.actions.pay', {
										amount: `${orderPrice.price} ${finance.value.currency}`,
									})
								) : (
									t('pageResource.paymentStep.actions.calculate')
								)}
							</Button>
						</form>
					)}
				</Section>
			</div>
		)
	}
)

export default PageResource
