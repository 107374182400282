import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as Logo } from 'assets/logo.svg'
import Button from 'components/UIKit/Button'
import { URL_MAP } from 'containers/App'
import { Trans, useTranslation } from 'react-i18next'
import Section from 'components/Section'
import { Link } from 'react-router-dom'
const cx = classNames.bind(styles)

const PageHome = memo(() => {
	const [t] = useTranslation()
	return (
		<div className={cx('Component', 'container_fluid')}>
			<div className={cx('Content')}>
				<Logo className={cx('Logo')} />
				<h3 className={cx('Name')}>myeoswallet</h3>
				<Section className={cx('Section')}>
					<nav className={cx('Nav')}>
						<Button
							tag='link'
							to={URL_MAP.generator}
							color='green'
							type='button'
						>
							{t('pageHome.generatorLink')}
						</Button>
						<Button tag='link' to={URL_MAP.resource} color='blue' type='button'>
							{t('pageHome.resourceLink')}
						</Button>
					</nav>
				</Section>

				<h1 className={cx('Title')}>{t('pageHome.title')}</h1>

				<div className={cx('Params')}>
					<div>
						<img src={require('assets/images/home_eos_1.svg')} alt='' />
						<p>
							<Trans i18nKey='pageHome.list.0'>
								<a href='https://myeoswallet.net/generator' target='_blank'>
									.
								</a>
							</Trans>
						</p>
					</div>
					<div>
						<img src={require('assets/images/home_eos_2.svg')} alt='' />
						<p>
							<Trans i18nKey='pageHome.list.1'>
								<a href='https://myeoswallet.net/generator' target='_blank'>
									.
								</a>
							</Trans>
						</p>
					</div>
					<div>
						<img src={require('assets/images/home_eos_3.svg')} alt='' />
						<p>
							<Trans i18nKey='pageHome.list.2'>
								<a href='https://myeoswallet.net/resources' target='_blank'>
									.
								</a>
							</Trans>
						</p>
					</div>
					<div>
						<img src={require('assets/images/home_eos_4.svg')} alt='' />
						<p>
							<Trans i18nKey='pageHome.list.3'>
								<a href='https://en.wikipedia.org/wiki/EOS.IO' target='_blank'>
									.
								</a>
							</Trans>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
})

export default PageHome
