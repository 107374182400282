export default {
    title: 'Company',
    text: [
        'Requisites "IN-CTO HOLDINGS PTE. LTD."',
        '',
        'Certificate of incorporation: 201710457E',
        'Address: 9 Raffles Place, #26-01 Republic Plaza, Singapore, (048619)',
        'Email: dveoservice@gmail.com',
        '',
        'Bank information:',
        '',
        'Bank name: Unibank OJSC',
        'Bank address: 5/1, Nothern ave, Yerevan, RA',
        'SWIFT: UNIJAM22',
        'Account: 24149001262202 (RUB)',
        'Account: 24149001262200 (USD)',
        'Account: 24149001262201 (EUR)',
    ],
}