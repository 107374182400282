import React, { Fragment } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as IconAngleRight } from 'assets/icons/angle_right.svg'
const cx = classNames.bind(styles)

type Props = {
	className?: string
	currentStep: number
	stepList: number[]
	/** @default green */
	color?: 'blue' | 'green'
}
/**
 * Reusable component to render process in steps.
 * Have to be rendered inside `position: relative` element
 * Have two color schemas - blue & green
 */
const Steps = ({
	className = '',
	currentStep,
	stepList,
	color = 'green',
}: Props) => {
	const totalSteps = stepList.length
	return (
		<div className={cx('StepList', `StepList_${color}`, className)}>
			{stepList.map((step, i) => {
				const isCompete = step < currentStep
				const isCurrentStep = step === currentStep
				const notLastStep = i !== totalSteps - 1
				return (
					<Fragment key={step}>
						<span
							className={cx(
								'Step',
								(isCompete || isCurrentStep) && 'Step_active'
							)}
						>
							0{i + 1}
						</span>
						{notLastStep && (
							<IconAngleRight
								className={cx('StepIcon', isCompete && 'StepIcon_active')}
							/>
						)}
					</Fragment>
				)
			})}
		</div>
	)
}

export default Steps
