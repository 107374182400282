import React, { ReactNode } from 'react'
import Field, { Props as FieldProps } from '../Field'
import { ReactComponent as IconTriagle } from 'assets/icons/triagle.svg';
import useDropdown from 'helpers/useDropdown'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

type Option = {
	element: ReactNode
	closeOnClick?: boolean
	key?: string | number
}

export type Props = FieldProps & {
	options: Option[]
}

export default ({ options, classNameWrapper, ...fieldProps }: Props) => {
	const dropdown = useDropdown({})

	return (
		<div ref={dropdown.ref} className={cx('Component')}>
			<Field
				{...fieldProps}
				onClick={dropdown.toggle}
				readOnly
				classNameWrapper={cx(
					classNameWrapper,
					dropdown.open && 'SelectField_active'
				)}
				rightControl={<IconTriagle className={cx('Icon', dropdown.open && 'Icon_active')} />}
			/>
			{dropdown.open && (
				<ul className={cx('List')}>
					{options.map(({ closeOnClick = true, element, key }, i) => (
						<li
							onClick={
								closeOnClick
									? () => {
											dropdown.setOpen(false)
									  }
									: undefined
							}
							key={key ?? i}
						>
							{element}
						</li>
					))}
				</ul>
			)}
		</div>
	)
}
