export default {
	title: 'How it works?',
	description: 'The name of your EOS account is public information and serves as a unique identifier on the EOS blockchain network, it can be interpreted as a login. The generated private key is a code that grants access to the EOS account and allows you to perform outgoing operations, it can be interpreted as a password. In order to work with an EOS account (for example to send transactions) you will need a third-party wallet service that works with EOS blockchain network. This site does not provide any wallet related services.',

	wallet: {
		title: 'Account Creation Service',
		description:
			'The cost of the service depends on the current workload in the blockchain network and at the moment is 100 rubles',
		steps: [
			'Select "I want to create account."',
			'Indicate the desired account name, in case the name is taken you will need to choose another one.',
			'After a successfully selected account name click "Generate Keys".',
			'Save your information (account name, public and private key).',
			'Confirm your agreement with the terms of the contract including privacy policy.',
			'Select a payment method.',
			'Pay for the service.',
			'After payment for the service an electronic account will be created for you. This usually takes a few seconds, but the deadline is 24 hours.',
			'You can use the private key to import into any electronic service that works with EOS accounts.',
		],
	},

	resource: {
		title: 'Stake Resources Service',
		description:
			'The cost of the service depends on the amount of resources that you want to add to your account. The more resources the greater will be the total cost.',
		steps: [
			'Select "I want to stake resource"',
			'Indicate the name of the account to manage its resources.',
			{
				title: 'Select a resource',
				steps: [
					'CPU - affects the maximum number of outgoing transactions per day. You must keep the usage level below 100%. The current state of usage is displayed on the site.',
					'NET - affects the maximum number of outgoing transactions per day. You must keep the usage level below 100%. The current state of usage is displayed on the site.',
					'RAM - affects the maximum amount of information associated with your account. You must have a usage level of less than 100% in order to be able to expand the number of balances. The current state of usage is displayed on the site.	',
				],
			},
			'Indicate the amount by which you wish to increase each of the resources.',
			'Confirm your agreement with the terms of the contract including privacy policy.',
			'Select a payment method.',
			'Pay for the service.',
			'Your resources will be replenished, you can see the state of their usage on this site or in any third-party applications for working with EOS accounts. This usually takes a few seconds, but the deadline is 24 hours.',
		],
	},
}
