import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg'

const cx = classNames.bind(styles)

const FormDisabled = memo(({ children }) => {
	return (
		<div className={cx('Component')}>
			<IconWarning />
			{children}
		</div>
	)
})

export default FormDisabled
