import React, { memo, PropsWithChildren } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

const Attention = memo(({ children }: PropsWithChildren<{}>) => {
	return (
		<div className={cx('Attention')}>
			<span className={cx('AttentionIcon')}>!</span>
			<p>{children}</p>
		</div>
	)
})
export default Attention
