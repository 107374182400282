import error from './error'
import pageGenerateWallet from './pageGenerateWallet'
import pageResource from './pageResource'
import pageHome from './pageHome'
import pageTerms from './pageTerms'
import pagePrivacy from './pagePrivacy'
import pageCompany from './pageCompany'
import pageOrder from './pageOrder'
import pageFaq from './pageFaq'
import pageError from './pageError'
import versionController from './versionController'
import modalCopyAgreement from './modalCopyAgreement'
import pageUnsupported from './pageUnsupported'

export default {
	error,
	pageGenerateWallet,
	pageResource,
	pageHome,
	pagePrivacy,
	pageTerms,
	pageCompany,
	pageFaq,
	pageOrder,
	pageError,
	pageUnsupported,
	versionController,
	modalCopyAgreement,

	footer: {
		company: 'Company',
		privacy: 'Privacy',
		terms: 'Terms',
	},

	global: {
		cardHint:
			'Allowed currency for MasterCard is RUB, USD, EUR. Allowed currency for Visa is RUB. Allowed currency for Russian Federation cards is RUB',

		copied: 'Copied!',

		paymentSystem: 'Payment method',
		paymentSystem_piastrix: 'Bank card',
		paymentSystem_pc4store: 'PC4Store',
	},
}
