import React, { PureComponent  } from 'react'
import PageError from 'containers/Pages/Error'
import { RootContext } from 'helpers/rootContext';

class ErrorBoundary extends PureComponent {
    static contextType = RootContext;

	componentDidCatch() {
		this.context.setErrorStatus(500)
	}

	render() {
		if (this.context.errorStatus) {
			return <PageError status={this.context.errorStatus} />
		}
		return this.props.children
	}
}

export default ErrorBoundary
