export default {
	generatorLink: 'Я хочу создать новый аккаунт',
	resourceLink: 'Я хочу добавить ресурсы на свой аккаунт',


	title: 'Чтобы создать EOS аккаунт\nили управлять EOS ресурсами\nвоспользуйтесь самым дешевым в мире веб-сервисом!',
	list: [
		'Блокчейн это современная технология и многие хотели бы узнать как создать EOS аккаунт. Нажмите "Я хочу создать новый аккаунт" или перейдите по ссылке <0>https://myeoswallet.net/generator</0>, придумайте название своему аккаунту и проверьте что он доступен абсолютно бесплатно. Публичный и приватный ключ будут сгенерированы безопасно и доступны для использования сразу после успешной оплаты.',
		'Используйте банковскую карту, чтобы оплатить в рублях, долларах или евро.\nМы работаем с Visa и Mastercard.\nТакого рода услуга стоит не более 100 рублей.',
		'Если вы хотите проверить ресурсы, купить или стейкнуть ЦПУ НЕТ РАМ (CPU NET RAM) для EOS аккаунта, то нажмите "Я хочу добавить ресурсы на свой аккаунт" или перейдите по ссылке <0>https://myeoswallet.net/resources</0> и следуйте простым инструкциям. Это очень быстро и не займет у вас более 3 минут, без каких-либо комиссий или скрытых платежей.',
		'Больше информации касательно работы EOS блокчейна, смарт контрактов и миллионов транзакций в секунду можно найти в википедии <0>https://en.wikipedia.org/wiki/EOS.IO</0>',
	],
}
