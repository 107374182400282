import React, { ReactNode, memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

type Props = {
	header?: ReactNode
	children?: ReactNode
	beforeContent?: ReactNode
	afterContent?: ReactNode
	classNameHeader?: string
	classNameContent?: string
	className?: string
}

const Section = memo(
	({
		header,
		children,
		beforeContent,
		afterContent,
		className = '',
		classNameHeader = '',
		classNameContent = '',
	}: Props) => {
		return (
			<section className={cx('Component', className)}>
				{beforeContent}
				{header && <h2 className={cx('Title', classNameHeader)}>{header}</h2>}
				<div className={cx('Content', classNameContent)}>{children}</div>
				{afterContent}
			</section>
		)
	}
)

export default Section
