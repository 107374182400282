import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'
import 'array-flat-polyfill'
import './i18n'
import 'scss/index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import Root from 'Root'

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://12ee6d6233b24288a5be5de56ff6b75d@sentry.list.family/25',
	})
}

if (!('scrollBehavior' in document.documentElement.style))
	import('scroll-behavior-polyfill')

// remove initial preloader
const globalPreloader = document.querySelector('#global-preloader')
globalPreloader?.parentNode?.removeChild(globalPreloader)
const rootElement = document.getElementById('root')

if (rootElement?.hasChildNodes()) {
	ReactDOM.hydrate(
		<React.StrictMode>
			<Root />
		</React.StrictMode>,
		rootElement
	)
} else {
	ReactDOM.render(
		<React.StrictMode>
			<Root />
		</React.StrictMode>,
		rootElement
	)
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
