import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

const PageCompany = memo(() => {
	const [t] = useTranslation()
	const text: string[] = t('pageCompany.text', { returnObjects: true })
	return (
		<div className={cx('Component', 'container')}>
			<article className={cx('Content')}>
				<h2 className={cx('Title')}>{t('pageCompany.title')}</h2>
				{text.map((text, i) => {
					return <p key={i}>{text}</p>
				})}
			</article>
		</div>
	)
})

export default PageCompany
