import React, {
	Fragment,
	PropsWithChildren,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Button from 'components/UIKit/Button'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

const isUnsupportedSafari = function () {
	const userAgent = window.navigator.userAgent.toLowerCase() || ''
	const key = 'version/'
	return (
		/safari/.test(userAgent) &&
		parseInt(userAgent.substr(userAgent.indexOf(key) + key.length, 3)) < 11
	)
}

/** Support all browsers, that supports `grid` and Safari 11 and more */
const BrowserValidator = ({ children }: PropsWithChildren<{}>) => {
	const [t] = useTranslation()
	const [showContent, setShowContent] = useState(false)
	const isBrowserSupport =
		window.navigator?.userAgent === 'ReactSnap' ||
		(window.CSS &&
			window.CSS.supports('display', 'grid') &&
			!isUnsupportedSafari())

	const canRender = showContent || isBrowserSupport

	useEffect(() => {
		const root = document.getElementById('root')
		if (canRender) {
			root?.classList.remove(cx('Root'))
		} else {
			root?.classList.add(cx('Root'))
		}
	}, [canRender])

	if (canRender) return <Fragment>{children}</Fragment>

	return (
		<Fragment>
			<div className={cx('BrowserValidator')}>
				<p className={cx('Text')}>{t('pageUnsupported.text')}</p>
				<br />
				<Button color='red' onClick={() => setShowContent(true)}>
					{t('pageUnsupported.btn')}
				</Button>
			</div>
		</Fragment>
	)
}

export default BrowserValidator
