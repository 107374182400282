import React, { memo, useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import useDropdown from 'helpers/useDropdown'
import { ReactComponent as IconTriagle } from 'assets/icons/triagle.svg'
import { useLocation, NavLink } from 'react-router-dom'
import { URL_MAP } from 'containers/App'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

const DropdownForm = memo(() => {
	const dropdown = useDropdown({})
	const [t] = useTranslation()
	const { pathname } = useLocation()
	const isGeneratorForm = pathname.includes(URL_MAP.generator)
	const isResourceForm = pathname.includes(URL_MAP.resource)
	const show = !isGeneratorForm && !isResourceForm

	useEffect(() => {
		dropdown.setOpen(false)
	}, [show])

	if (!isGeneratorForm && !isResourceForm) return null
	const generateWalletLabel = t('pageGenerateWallet.title')
	const recourcesLabel = t('pageResource.title')
	return (
		<div ref={dropdown.ref} className={cx('Component')}>
			<button
				type='button'
				className={cx('BtnControl', dropdown.open && 'BtnControl_open')}
				onClick={dropdown.toggle}
			>
				<span>
					{isGeneratorForm ? generateWalletLabel : recourcesLabel}
					<IconTriagle />
				</span>
			</button>

			{dropdown.open && (
				<nav className={cx('Nav')}>
					<NavLink
						onClick={dropdown.toggle}
						activeClassName={cx('LinkActive')}
						to={URL_MAP.generator}
					>
						{generateWalletLabel}
					</NavLink>
					<NavLink
						onClick={dropdown.toggle}
						activeClassName={cx('LinkActive')}
						to={URL_MAP.resource}
					>
						{recourcesLabel}
					</NavLink>
				</nav>
			)}
		</div>
	)
})
export default DropdownForm
