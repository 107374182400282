import { useState, useRef, useEffect } from 'react'

type UseDropdown = {
	onClose?(): void
}

export default ({ onClose }: UseDropdown = {}) => {
	const [open, setOpen] = useState(false)
	const ref = useRef<HTMLDivElement>(null)
	const toggle = () => setOpen(!open)

	useEffect(() => {
		const mousedownListener = (e: MouseEvent) => {
			if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
				document.removeEventListener('mousedown', mousedownListener)
				setOpen(false)
			}
		}

		const keydownListener = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				setOpen(false)
				e.preventDefault()
				e.stopPropagation()
			}
		}

		if (open) {
			document.addEventListener('mousedown', mousedownListener)
			document.addEventListener('keydown', keydownListener)
		} else {
			onClose?.()
		}

		return () => {
			document.removeEventListener('keydown', keydownListener)
			document.removeEventListener('mousedown', mousedownListener)
		}
	}, [open])

	return { open, toggle, ref, setOpen }
}
