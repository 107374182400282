import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { URL_MAP } from 'containers/App'
import { ReactComponent as IconMastercard } from 'assets/icons/payment_mastercard.svg';
import { ReactComponent as IconVisa } from 'assets/icons/payment_visa.svg';
const cx = classNames.bind(styles)

const Footer = memo(() => {
	const [t] = useTranslation()
	return (
		<footer className={cx('Component')}>
			<div className={cx('container_fluid', 'Row')}>
				<nav className={cx('Nav')}>
					<Link to={URL_MAP.company}>{t('footer.company')}</Link>
					<Link to={URL_MAP.privacy}>{t('footer.privacy')}</Link>
					<Link to={URL_MAP.terms}>{t('footer.terms')}</Link>
					<Link to={URL_MAP.faq}>{t('pageFaq.title')}</Link>
				</nav>
				<div className={cx('Payments')}>
					<IconVisa />
					<IconMastercard />
				</div>
				<p className={cx('Copyright')}>2022</p>
			</div>
		</footer>
	)
})

export default Footer
