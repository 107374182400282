/**
 * @note the component is ready to use. You can copy the file
 * to your project and modify if you need (for example rules of
 * updating app) or use as is. You can find example in the the <App />
 * or <WithVersionController /> helper component
 *
 * @todo:
 * - make as node_module
 */
import React, {
	PropsWithChildren,
	useState,
	Fragment,
	useEffect,
	useRef,
	ReactNode,
} from 'react'

type VersionControllerProps = {
	/** start watching for server or only render child */
	isEnabled: boolean
	/** ws server endpoint  */
	url: string
	version: string
	/** will call if server will send new version. Clean cash or do smth at the moment */
	onUpdateFound: () => void | Promise<any>
	/** call this with `onClick` prop in UpdateScreen or on reload page on `reconnectingLimit` if it passed */
	onUpdate?: () => void
	/** after some reconnections in a row call `onUpdateFound` and reload page  */
	reconnectingLimit?: number
	/** @defult 1000 (in ms) */
	reconnectingTimeout?: number
	LoadingScreen: ReactNode
	/** will show on reconnecting to server. If it is not passed - will show `<LoaderScreen />` */
	ReconnectingScreen?: ReactNode
	/**
	 * we display the screen on found new version and remove previous content.
	 * Expect to render button with passed `onClick` (that set `updating` flag and reloads page)
	 * prop and `updating` flag in order to render some loader or disable button
	 */
	UpdateScreen: (props: {
		onClick: () => void
		updating: boolean
	}) => JSX.Element
}

const isPrerendering = window.navigator?.userAgent === 'ReactSnap'

/**
 * Component-helper with out internal cache logic.
 * @motivation We have to show for user the latest version of the
 * app and don't allow to use old app as soon as it possible.
 *
 * On build we pass version to the App (now it is commit hash)
 * When user opens the page - we firstly connect it to the WS server,
 * that has expected build version. If the version in the App and
 * in the server is difficult we have to make smth (like clean cache).
 * Since it is critically to have last version - we have to always have
 * connection with the server. Our connection has this states:
 *
 * 1) `connected` -  we connected to server successfuly and received some data.
 * If connection is not "loaded" -- show `<LoadingScreen />` only.
 *
 * 2) `loaded` - we waiting for first answer from the server in order to
 * update App in background or show `<UpdateScreen />`.
 *
 * 3) `reconnecting` - if for some reasons we disconnected from
 * the server - we try to reconnect until user will not be connected or some
 * other reasons. On reconnecting we show `<ReconnectingScreen />` if it exist or `<LoadingScreen />` for user;
 */
const VersionController = ({
	children,
	isEnabled,
	url,
	onUpdateFound,
	onUpdate,
	reconnectingLimit,
	reconnectingTimeout = 1000,
	version,
	LoadingScreen,
	ReconnectingScreen,
	UpdateScreen,
}: PropsWithChildren<VersionControllerProps>) => {
	/** need to control time, when first message received */
	const isInitMessageReceived = useRef(false)
	const reconnectionCountRef = useRef(0)
	const [loadingVersion, setLoadingVersion] = useState(
		isEnabled && !isPrerendering
	)
	const [isReconnecting, setIsReconnecting] = useState(false)
	const [hasNewVersion, setHasNewVersion] = useState(false)
	const [updating, setUpdating] = useState(false)

	const updateApp = () => {
		setUpdating(true)
		onUpdate?.()
		window.location.reload()
	}

	useEffect(() => {
		if (isEnabled && !isPrerendering) {
			const connect = () => {
				const socket = new WebSocket(url ?? '')

				/** Reconnecting flow. On error close current connection, show reconnection message */
				socket.onopen = () => {
					setIsReconnecting(false)
					// reset value - we connected successfuly
					reconnectionCountRef.current = 0
				}

				socket.onclose = (e) => {
					setTimeout(() => {
						connect()
						// increment count of reconnections in a row
						reconnectionCountRef.current += 1
					}, reconnectingTimeout)
				}

				socket.onerror = async (err) => {
					setIsReconnecting(true)
					if (
						reconnectingLimit &&
						reconnectingLimit <= reconnectionCountRef.current
					) {
						await onUpdateFound()
						updateApp()
					} else {
						socket.close()
					}
				}

				socket.onmessage = async (event) => {
					const realVersion = event.data
					const isNewVersionReceived = version !== realVersion

					setHasNewVersion(isNewVersionReceived)
					if (isNewVersionReceived) {
						await onUpdateFound()
						/**
						 * same as `connected` but not `loaded` state - reload app only
						 */
						if (!isInitMessageReceived.current) {
							return updateApp()
						}
					}

					setLoadingVersion(false)
					isInitMessageReceived.current = true
				}
			}

			connect()
		}
	}, [])

	if (!hasNewVersion)
		return (
			<Fragment>
				{loadingVersion && LoadingScreen}
				{children}
				{isReconnecting && (ReconnectingScreen || LoadingScreen)}
			</Fragment>
		)

	return <UpdateScreen onClick={updateApp} updating={updating} />
}

export default VersionController
