export default {
	title: 'Account resource',
	disabled: 'Service is unavailable for technical reasons.\nPlease try later',
	back: 'BACK',
	usernameStep: {
		title: 'Enter account name',
		label: 'Account name',
		rules: {
			isFound_true: 'account exist',
			isFound_false: 'account not found',
		},
		actions: {
			validate: 'Validate account name',
			submit: 'Choose resource',
		},
	},

	paymentStep: {
		title: 'Account resource staking',
		attention: 'Choose any combination of resources that you want to add to your account. If the current CPU or NET usage is more than 100% you will not be able to make any outgoing transactions. If the RAM usage is more than 100% you will not be able to add any new tokens to this account',
		agree: 'I agree with <0>Terms</0>',

		params: {
			account: 'Account name',
		},

		currency: {
			label: 'Choose payment currency',
			placeholder: 'Payment currency',
		},
		actions: {
			calculate: 'Enter the resource value',
			pay: 'Pay {{ amount }}',
		},
	},
}
