import { createContext, useContext } from 'react'

export type ErrorCode = 500 | 503 | 404 | null


type RootContextType = {
	errorStatus: ErrorCode,
	setErrorStatus: (v: ErrorCode) => void
}


export const RootContext = createContext<RootContextType>({} as any)
export const useRootContext = () => {
	return useContext(RootContext)
}
