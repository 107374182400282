import React, {
	memo,
	useEffect,
	useState,
	useRef,
	PropsWithChildren,
	createContext,
	useContext,
} from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import FocusLock from 'react-focus-lock'
import { createPortal } from 'react-dom'

const cx = classNames.bind(styles)

export type ModalProps = {
	isOpen: boolean
	close?: () => void
}

type Props = PropsWithChildren<ModalProps>

const ANIM_DURATION_BG = 350
export const ANIM_DURATION_MODAL = 800

// Helper to control need we close modal on
// click overlay or modal directly
let shouldClose: null | boolean = null
const setShouldClose = (v: typeof shouldClose) => {
	shouldClose = v
}

/** helpful UIKit/Modal context, that allow correctly close modal with initiate close animation firstlyu */
export const ModalContext = createContext({ close: () => {} })
export const useModalContext = () => useContext(ModalContext)

const isOverflow = () => document.body.style.overflow === 'hidden'

const setOverflow = (show: boolean) => {
	document.body.style.overflow = show ? 'hidden' : ''
}

const Modal = memo(({ isOpen, close, children }: Props) => {
	const [modalActive, setModalActive] = useState(false)
	const isFirstRenderRef = useRef(true)
	const closeTimeoutId = useRef<any>()
	const onExternalCloseRef = useRef<() => void>()
	const modalPortal = useRef(document.createElement('div')).current
	const modalWrapperRef = useRef<HTMLElement>(null)
	const isClosing = (modalActive || isOpen) && !(modalActive && isOpen)
	const showModal = modalActive && isOpen
	//  in order to display animation correctly
	const [appliedWithPortal, setAppliedWithPortal] = useState(false)

	/** create portal container, mount it and set "ready" flag */
	const prepareEnvironment = () => {
		modalPortal && document.body.appendChild(modalPortal)
		setAppliedWithPortal(true)
	}

	const unmountEnvironment = (
		onBeforeAnimation = () => setModalActive(false),
		onEndAnimation = close,
		withCloseAnimation = modalActive || isOpen
	) => {
		// enable body's overflow
		setOverflow(false)
		// remove ekyboard event listener
		window.onkeydown = null
		onBeforeAnimation()
		if (withCloseAnimation) {
			closeTimeoutId.current = setTimeout(() => {
				setAppliedWithPortal(false)
				onExternalCloseRef.current = undefined
				onEndAnimation?.()
				modalPortal && modalPortal.remove()
			}, ANIM_DURATION_MODAL)
		}
	}

	useEffect(
		() => () => {
			modalPortal && modalPortal.remove()
			isOverflow() && setOverflow(false)
			clearInterval(closeTimeoutId.current)
		},
		[]
	)

	/**
	 * on change `isOpen`
	 * if `isOpen` is true - create portal container, render it and set "ready" flag
	 * otherwise remove all event listeners and close modal
	 */
	useEffect(() => {
		if (isOpen) {
			prepareEnvironment()
			onExternalCloseRef.current = () => {
				unmountEnvironment(
					close,
					() => {
						setModalActive(false)
					},
					true
				)
				onExternalCloseRef.current = undefined
			}
		} else {
			if (isFirstRenderRef.current) {
				isFirstRenderRef.current = false
				return
			}
			onExternalCloseRef.current?.()
		}
	}, [isOpen])
	/**
	 * if portal container is ready - initiate animation, remove body's
	 * overflow, add listeners and do all required prepares to correctly
	 * display modal
	 */
	useEffect(() => {
		if (appliedWithPortal) {
			setModalActive(true)
			setOverflow(true)
			modalWrapperRef.current?.scrollTo(0, 0)

			window.onkeydown = (e: KeyboardEvent) => {
				if (e.key === 'Escape') unmountEnvironment()
			}
		}
	}, [appliedWithPortal])

	const handleOverlayOnClick = () => {
		if (shouldClose === null) {
			setShouldClose(true)
		}

		if (shouldClose) {
			unmountEnvironment()
		}
		setShouldClose(null)
	}

	if (!isOpen && !modalActive) return null

	return createPortal(
		<FocusLock>
			<ModalContext.Provider value={{ close: () => unmountEnvironment() }}>
				<aside
					ref={modalWrapperRef}
					onClick={handleOverlayOnClick}
					data-testid='modal-wrapper'
					style={{
						transitionDuration: `${ANIM_DURATION_BG / 1000}s`,
						transitionDelay: isClosing
							? `${(ANIM_DURATION_BG / 1000) * 0.8}s`
							: undefined,
					}}
					className={cx('Component', modalActive && isOpen && 'show')}
				>
					<div
						onMouseDown={() => setShouldClose(false)}
						onMouseUp={() => setShouldClose(false)}
						onClick={() => setShouldClose(false)}
						style={{
							transitionDuration: `${ANIM_DURATION_MODAL / 1000}s`,
							transitionDelay: showModal
								? `${(ANIM_DURATION_BG / 1000) * 0.2}s`
								: undefined,
						}}
						className={cx('Modal')}
					>
						{children}
					</div>
				</aside>
			</ModalContext.Provider>
		</FocusLock>,
		modalPortal
	)
})

export default Modal
