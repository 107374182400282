export default {
	title: 'Ресурсы аккаунта',
	disabled: 'Сервис недоступен по техническим причинам.\nПопробуйте еще раз позже',
	back: 'НАЗАД',
	usernameStep: {
		title: 'Введите имя аккаунта',
		label: 'Имя аккаунта',
		rules: {
			isFound_true: 'аккаунт существует',
			isFound_false: 'аккаунт не найден',
		},
		actions: {
			validate: 'Подтвердите имя аккаунта',
			submit: 'Выберите ресурсы',
		},
	},

	paymentStep: {
		title: 'Доступные ресурсы аккаунта',
		attention: 'Выберите произвольную комбинацию ресурсов, которые хотите добавить на аккаунт. Если текущая загрузка CPU или NET больше 100% вы не сможете совершать исходящие транзакции. Если загрузка RAM больше 100% вы не сможете добавлять новые токены на этот аккаунт',
		agree: 'Я согласен с <0>Условиями пользования</0>',

		params: {
			account: 'Имя аккаунта',
		},

		currency: {
			label: 'Выберите валюту для оплаты',
			placeholder: 'Валюта платежа',
		},
		actions: {
			calculate: 'Введите значение ресурсов',
			pay: 'Оплатите {{ amount }}',
		},
	},
}
