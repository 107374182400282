import React, { PropsWithChildren, Fragment } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg'
import { ReactComponent as IconCancel } from 'assets/icons/cancel.svg'
const cx = classNames.bind(styles)

type Props = {
	isHidden?: boolean
	isValid?: boolean
	className?: string
}

const Rule = ({
	isHidden,
	isValid,
	children,
	className = '',
}: PropsWithChildren<Props>) => {
	return (
		<p className={cx('Rule', isHidden && 'Rule_hidden', className)}>
			{!isHidden && (
				<Fragment>
					{isValid ? <IconSuccess /> : <IconCancel />}
					{children}
				</Fragment>
			)}
		</p>
	)
}

export default Rule
