import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { LinkProps, Link } from 'react-router-dom'
const cx = classNames.bind(styles)

// All available props for `button`
export type ButtonProps = {
	/** @default button*/
	tag?: 'button'
} & JSX.IntrinsicElements['button']

// All available props for react-router-dom <Link/> element
export type ButtonPropsLink = { tag: 'link' } & LinkProps

type Props = {
	color: 'red' | 'blue' | 'green' | 'link'
} & (ButtonProps | ButtonPropsLink)

const Button = memo(
	({ color, children, className = '', tag = 'button', ...props }: Props) => {
		const cls = cx('Btn', `Btn_${color}`, className)
		if (tag === 'link') {
		return <Link {...(props as ButtonPropsLink)} className={cls}><span>{children}</span></Link>
		} else {
		return <button {...(props as ButtonProps)} className={cls}><span>{children}</span></button>
		}
	}
)

export default Button
