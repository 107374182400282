export default {
	generatorLink: 'I want to create new account',
	resourceLink: 'I want to add resources to my account',

	title: 'Welcome to the world\'s cheapest EOS account creator\nand EOS resource management web service',
	list: [
		'If you want to know how to create EOS account in blockchain network than you are on the right page! Click "I want to create new account" or <0>https://myeoswallet.net/generator</0>, pick proper username and check if it is available absolutely for free. Your private and public keys will be generated securely and will be available for personal usage right after successful payment. ',
		'Use your credit or debit card to pay with RUB, USD, EUR for creating your personal EOS account. We accept Visa and MasterCard. The average price for such service is about $2.',
		'If you want to check, buy, stake CPU NET RAM of EOS account just click "I want to add resources to my account" or <0>https://myeoswallet.net/resources</0> and follow simple instructions. All process takes no more than 3 minutes, no hidden fees and no commissions.',
		'More information regarding EOS blockchain, smart contracts and throughput of millions of transactions per second can be found on wikipedia <0>https://en.wikipedia.org/wiki/EOS.IO</0>',
	],
}
