export default {
    title: 'Order #{{ orderId }}',

    status: {
        EOS_ERROR: 'Payment received. Fulfilling order',
        EOS_WAITING: 'Payment received. Fulfilling order',
        EXPIRED: 'Order expired',
        FINISHED_CREATE: 'Order succeed! You can use your account {{username}} with any electronic wallet service that operates with EOS',
        FINISHED_RESOURCE: 'Order succeed! Resources for your account {{username}} have been increased',
        PAYMENT_REFUSED: 'Payment not received',
        PAYMENT_SUCCESS: 'Payment received. Fulfilling order',
        WAITING: 'Waiting for payment',
    },

    hint: "Please refresh the page to check the latest transaction status",

    actions: {
        check: 'Check',
        tryAgain: 'Try again',
    },

    username: 'Account name',
    publicKey: 'Public key',
    orderType: 'Order type',
    transactionId: 'Transaction ID',
    price: 'Price',
    currency: 'Currency',

    walletLinks:
        'You can access your account with any electronic wallet service like <0>{{link1}}</0> or <1>{{link2}}</1>',
}