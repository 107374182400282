import React, { useEffect, useState } from 'react'

export type UseFormField<T> = {
	value: T
	error: string
	change: React.Dispatch<React.SetStateAction<T>>
	changeError: React.Dispatch<React.SetStateAction<string>>
}

export function useFormField<T>(
	initialValue: T,
	withResetErrorOnChange = true
) {
	const [value, setValue] = useState(initialValue)
	const [error, setError] = useState('')

	useEffect(() => {
		withResetErrorOnChange && setError('')
	}, [value, withResetErrorOnChange])

	return {
		value,
		error,
		change: setValue,
		changeError: setError,
	}
}
