import React, { memo, useEffect } from 'react'
import {
	Route,
	RouteComponentProps,
	Switch,
	useLocation,
} from 'react-router-dom'
import PageHome from 'containers/Pages/Home'
import Footer from 'components/Footer'
import Header from 'components/Header'
import PageGenerateWallet from 'containers/Pages/GenerateWallet'
import PageResource from 'containers/Pages/Resource'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import LanguageSwitcher from 'components/LanguageSwitcher'
import AppInitializer from 'containers/Helpers/AppInitializer'
import PagePrivacy from 'containers/Pages/Privacy'
import PageCompany from 'containers/Pages/Company'
import PageError from 'containers/Pages/Error'
import ErrorBoundary from 'containers/Helpers/ErrorBoundary'
import PageOrder from 'containers/Pages/Order'
import PageTerms from 'containers/Pages/Terms'
import WithVersionController from 'containers/Helpers/WithVersionController'
import PageFaq from 'containers/Pages/Faq'
const cx = classNames.bind(styles)

export const URL_MAP = {
	home: '/',
	generator: '/generator',
	resource: '/resources',
	privacy: '/privacy',
	terms: '/terms',
	faq: '/how-to-buy',
	company: '/company',
	order: '/order/:id',
}

const PageViewTracking = memo(() => {
	const { pathname } = useLocation()
	useEffect(() => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'Pageview',
			pagePath: pathname,
			pageTitle: pathname,
		})
	}, [pathname])

	return null
})

const App = () => {
	const { pathname } = useLocation()

	const isGeneratorForm = pathname.includes(URL_MAP.generator)
	const isResourceForm = pathname.includes(URL_MAP.resource)

	return (
		<WithVersionController>
			<AppInitializer>
				<main
					className={cx('Component', {
						Component_generator: isGeneratorForm,
						Component_resource: isResourceForm,
					})}
				>
					<Header />
					<ErrorBoundary>
						<PageViewTracking />
						<Switch>
							<Route path={URL_MAP.home} exact component={PageHome} />
							<Route path={URL_MAP.generator} component={PageGenerateWallet} />
							<Route path={URL_MAP.resource} component={PageResource} />
							<Route path={URL_MAP.privacy} component={PagePrivacy} />
							<Route path={URL_MAP.terms} component={PageTerms} />
							<Route path={URL_MAP.faq} component={PageFaq} />
							<Route path={URL_MAP.company} component={PageCompany} />
							<Route path={URL_MAP.order} component={PageOrder} />
							<Route path='*' component={PageError} />
						</Switch>
						<LanguageSwitcher />
					</ErrorBoundary>
				</main>
				<Footer />
			</AppInitializer>
		</WithVersionController>
	)
}

export default App
