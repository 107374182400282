import React, { memo, Fragment } from 'react'
import validator from 'helpers/validator'
import { ValidateRule } from '.'
import { useTranslation } from 'react-i18next'
import Field from 'components/UIKit/Field'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import FormField from 'components/UIKit/FormField'
import Rule from 'components/UIKit/Rule'
const cx = classNames.bind(styles)

type Props = {
	validateRule: ValidateRule
	isFreeUserName: null | boolean
	username: string
	setUserName: (v: string) => void
}

const UsernameStep = memo(
	({ username, setUserName, validateRule, isFreeUserName }: Props) => {
		const [t] = useTranslation()

		const generateRandomUsername = () => {
			let randomUsername = ''
			const chars = 'abcdefghijklmnopqrstuvwxyz12345'
			for (let i = 0; i < 12; i++) {
				randomUsername += chars[Math.floor(Math.random() * chars.length)]
			}
			setUserName(randomUsername)
		}

		return (
			<Fragment>
				<FormField
					label={
						<Fragment>
							<span>{t('pageGenerateWallet.usernameStep.label')}</span>
							<button
								type='button'
								onClick={generateRandomUsername}
								className={cx('Btn_link')}
							>
								{t('pageGenerateWallet.usernameStep.generateBtn')}
							</button>
						</Fragment>
					}
				>
					<Field
						value={username}
						onChange={(e) => setUserName(validator(e, username).toLowerCase())}
						maxLength={12}
						placeholder={t('pageGenerateWallet.usernameStep.label')}
						valid={isFreeUserName}
					/>
				</FormField>

				<div className={cx('RuleList')}>
					<Rule isValid={validateRule.length}>
						{t('pageGenerateWallet.usernameStep.rules.length')}
					</Rule>
					<Rule isValid={validateRule.pattern}>
						{t('pageGenerateWallet.usernameStep.rules.pattern')}
					</Rule>
					<Rule
						isHidden={isFreeUserName === null}
						isValid={isFreeUserName ?? undefined}
					>
						{t(`pageGenerateWallet.usernameStep.rules.isFree_${isFreeUserName}`)}
					</Rule>
				</div>
			</Fragment>
		)
	}
)

export default UsernameStep
