import React, { useState, useEffect } from 'react'
import App from 'containers/App'
import { BrowserRouter } from 'react-router-dom'
import client from 'apollo'
import { ApolloProvider } from '@apollo/react-hooks'
import { RootContext, ErrorCode } from 'helpers/rootContext'
import BrowserValidator from 'containers/Helpers/BrowserValidator'

const Root = () => {
	const [error, setError] = useState<ErrorCode>(null)
	return (
		<BrowserValidator>
			<ApolloProvider
				client={client({
					onErrorReceived: setError,
				})}
			>
				<BrowserRouter>
					<RootContext.Provider
						value={{
							errorStatus: error,
							setErrorStatus: setError,
						}}
					>
						<App />
					</RootContext.Provider>
				</BrowserRouter>
			</ApolloProvider>
		</BrowserValidator>
	)
}

export default Root
