import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { from } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
const isPrerendering = window.navigator?.userAgent === 'ReactSnap'
const client = ({ onErrorReceived }: { onErrorReceived: (v: any) => void }) => {
	let link = (undefined as any) as ReturnType<typeof split>

	if (isPrerendering) {
		link = createHttpLink({
			uri: process.env.REACT_APP_API_ENDPOINT,
		})
	} else {
		const httpLink = createHttpLink({
			uri: process.env.REACT_APP_API_ENDPOINT,
		})
		const wsLink = new WebSocketLink({
			uri: process.env.REACT_APP_WS_ENDPOINT!,
			options: {
				reconnect: true,
			},
		})

		// using the ability to split links, you can send data to each link
		// depending on what kind of operation is being sent
		link = split(
			// split based on operation type
			({ query }) => {
				const definition = getMainDefinition(query)
				return (
					definition.kind === 'OperationDefinition' &&
					definition.operation === 'subscription'
				)
			},
			wsLink,
			httpLink
		)
	}

	const errorCodes = onError((error) => {
		const { networkError } = error

		// display any error only if user is online
		if (networkError) {
			const status = (networkError as any).statusCode

			if (
				networkError.toString() === 'TypeError: Failed to fetch' ||
				[503, 502].includes(status)
			) {
				onErrorReceived(503)
			} else if ([500, 504].includes(status)) {
				onErrorReceived(500)
			}
		}
	})

	return new ApolloClient({
		link: from([errorCodes, link]),
		cache: new InMemoryCache(),
	})
}

export default client
