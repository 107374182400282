import React, { memo, Fragment } from 'react'
import validator from 'helpers/validator'
import { useTranslation } from 'react-i18next'
import Field from 'components/UIKit/Field'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import FormField from 'components/UIKit/FormField'
import Rule from 'components/UIKit/Rule'
const cx = classNames.bind(styles)

type Props = {
	isFound: null | boolean
	username: string
	setUserName: (v: string) => void
}

const UsernameStep = memo(({ username, setUserName, isFound }: Props) => {
	const [t] = useTranslation()

	return (
		<Fragment>
			<FormField label={t('pageGenerateWallet.usernameStep.label')}>
				<Field
					value={username}
					onChange={(e) => setUserName(validator(e, username).toLowerCase())}
					maxLength={12}
					placeholder={t('pageResource.usernameStep.label')}
					valid={isFound}
				/>
			</FormField>

			<div className={cx('RuleList')}>
				<Rule isHidden={isFound === null} isValid={isFound ?? undefined}>
					{t(`pageResource.usernameStep.rules.isFound_${isFound}`)}
				</Rule>
			</div>
		</Fragment>
	)
})

export default UsernameStep
