import React, { PropsWithChildren, Fragment } from 'react'
import useSettingsQuery from 'helpers/useSettingsQuery'
import Preloader from 'components/Preloader'
const AppInitializer = ({ children }: PropsWithChildren<{}>) => {
	const settingsQuery = useSettingsQuery()

	if (settingsQuery.loading) return <Preloader />

	return <Fragment>{children}</Fragment>
}

export default AppInitializer
