import React, { memo } from 'react'
import { ReactComponent as IconCopy } from 'assets/icons/copy.svg'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

const Param = memo(
	({
		label,
		value,
		copy,
	}: {
		label: string
		value: string
		copy?(v: string): void
	}) => {
		return (
			<div className={cx('Param')}>
				<p className={cx('ParamLabel')}>{label}</p>

				<div className={cx('ParamValue')}>
					<span>{value}</span>
					{copy && (
						<button
							type='button'
							className={'BtnCopy'}
							onClick={() => copy(value)}
						>
							<IconCopy />
						</button>
					)}
				</div>
			</div>
		)
	}
)

export default Param
