/** Language map of language id and label */
export const LANGUAGES_MAP = {
	ru: 'RU',
	en: 'EN',
}

/** Language ids */
export const LANGUAGES = Object.keys(LANGUAGES_MAP)

/** Language ids type */
export type Languages = keyof typeof LANGUAGES_MAP

export const DEFAULT_PLATFORM_LNG: Languages = 'ru'
export const LOCAL_STORAGE_LNG_KEY = 'defaultLng'

export const ACCOUNT_NAME_LENGTH = 12