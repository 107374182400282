import { ChangeEvent } from 'react'

export default (
	{
		target: {
			value,
			validity: { valid },
		},
	}: ChangeEvent<HTMLInputElement>,
	currentValue: string
) => {
	return value && !valid ? currentValue : value
}
