import React, { memo, useState, useEffect, FormEvent } from 'react'
import UsernameStep from './UsernameStep'
import PaymentStep from './PaymentStep'
import { useTranslation } from 'react-i18next'
import ecc from 'eosjs-ecc'
import { ACCOUNT_NAME_LENGTH } from 'constants/'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Button from 'components/UIKit/Button'
import Steps from 'components/Steps'
import useSettingsQuery from 'helpers/useSettingsQuery'
import { useMutation, useQuery } from '@apollo/react-hooks'
import CREATE_ORDER from 'apollo/mutations/CREATE_ORDER.graphql'
import FormDisabled from 'components/FormDisabled'
import Section from 'components/Section'
import { RouteComponentProps } from 'react-router-dom'
import getErrorData from 'helpers/getErrorData'
import {
	Currency,
	OrderType,
	CreateOrderErrorCode,
} from 'apollo/__generated_types__/globalTypes'
import GET_ORDER_PRICE from 'apollo/queries/GET_ORDER_PRICE.graphql'
import {
	GetOrderPrice,
	GetOrderPriceVariables,
} from 'apollo/__generated_types__/GetOrderPrice'
import useModal from 'helpers/useModal'
import ModalCopyAgreement from 'containers/Modals/CopyAgreement'
import {
	CreateOrder,
	CreateOrderVariables,
} from 'apollo/__generated_types__/CreateOrder'
import { Settings_finances } from 'apollo/__generated_types__/Settings'
const cx = classNames.bind(styles)

export type Wallet = null | {
	privateKey: string
	publicKey: string
}

enum Step {
	usernameStep,
	paymentStep,
}

export function typedKeys<T>(v: T) {
	return Object.keys(v) as Array<keyof typeof v>
}

export function validateEos(value: string) {
	const length = value.length === ACCOUNT_NAME_LENGTH
	const pattern = !!value && /^[a-z1-5]*$/.test(value)
	return {
		length,
		pattern,
		isValid: length && pattern,
	}
}

export type ValidateRule = ReturnType<typeof validateEos>

const StepNumbers = Object.values(Step).filter((k) => !isNaN(+k)) as number[]

const useCheckUsername = (username: string, onUsernameFree: () => void) => {
	const [isFreeUsername, setIsFreeUsername] = useState<null | boolean>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<any>(undefined)
	const { endpoint } = useSettingsQuery().data!.setting!

	useEffect(() => {
		setIsFreeUsername(null)
	}, [username])

	const check = async () => {
		const url = setLoading(true)
		setError(undefined)
		setIsFreeUsername(null)

		await fetch(`${endpoint}/v2/state/get_account?account=${username}`, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.statusCode === 500) {
					setIsFreeUsername(true)
					onUsernameFree()
				} else {
					setIsFreeUsername(false)
				}
			})
			.catch((e) => {
				onUsernameFree()
				setIsFreeUsername(true)
				setError(e)
			})
		setLoading(false)
	}

	return {
		isFreeUsername,
		loading,
		error,
		check,
	}
}

const useWallet = () => {
	const [wallet, setWallet] = useState<Wallet>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<any>(undefined)

	const generate = async () => {
		setLoading(true)
		setError(undefined)
		setWallet(null)
		try {
			const privateKey = await ecc.randomKey()
			setWallet({
				privateKey,
				publicKey: ecc.privateToPublic(privateKey),
			})
			return true
		} catch (e) {
			setError(e)
			return false
		} finally {
			setLoading(false)
		}
	}

	return {
		wallet,
		loading,
		error,
		generate,
	}
}

const PageGenerateWallet = memo(
	({
		history,
		location,
	}: RouteComponentProps<any, any, { username?: string }>) => {
		const locationState = location.state
		const finances = useSettingsQuery().data!.finances!
		const [t] = useTranslation()
		const [username, setUserName] = useState('')
		const [errorMessage, setErrorMessage] = useState('')
		const [step, setStep] = useState(Step.usernameStep)
		const [agreeCopy, setAgreeCopy] = useState(false)
		const [agree, setAgree] = useState(false)
		const [finance, setFinance] = useState(finances[0])
		const wallet = useWallet()
		const modalCopyAgreement = useModal(false)

		const onUsernameFree = async () => {
			const result = await wallet.generate()
			if (result) {
				setStep(Step.paymentStep)
			}
		}

		const checkUsername = useCheckUsername(username, onUsernameFree)
		const isDisabledFlow = !useSettingsQuery().data!.setting!.isPaymentAllowed
		const isUserStep = step === Step.usernameStep
		const [
			createWalletMutation,
			{ loading: loadingCreateWalletMutation },
		] = useMutation<CreateOrder, CreateOrderVariables>(CREATE_ORDER, {
			variables: {
				input: {
					username,
					publicKey: wallet.wallet?.publicKey ?? '',
					financeId: finance?.id!,
					orderType: OrderType.CREATE,
				},
			},
		})

		const getOrderPriceQuery = useQuery<GetOrderPrice, GetOrderPriceVariables>(
			GET_ORDER_PRICE,
			{
				fetchPolicy: 'network-only',
				variables: {
					input: { financeId: finance?.id!, orderType: OrderType.CREATE },
				},
			}
		)

		useEffect(() => {
			if (locationState) {
				setUserName(locationState.username ?? '')
			}
		}, [])

		useEffect(() => {
			const { message } = getErrorData(getOrderPriceQuery.error)
			setErrorMessage(
				`${t('error.globalError')}${message ? ` : ${message}` : ''}`
			)
		}, [getOrderPriceQuery.error])

		useEffect(() => {
			setAgreeCopy(false)
			setAgree(false)
			setErrorMessage('')
		}, [step])

		const validateRule = validateEos(username)
		const disabled =
			(isUserStep && !validateRule.isValid) ||
			(step === Step.paymentStep && (!agree || !agreeCopy || !finance))

		const loading =
			wallet.loading ||
			checkUsername.loading ||
			loadingCreateWalletMutation ||
			getOrderPriceQuery.loading
		const errorOnGeneratingKeys =
			isUserStep && checkUsername.isFreeUsername && !!wallet.error

		const onErrorMessage = (message = '') => {
			setErrorMessage(
				`${t('error.globalError')}${message ? ` : ${message}` : ''}`
			)
		}

		const createWallet = () => {
			modalCopyAgreement.close()
			createWalletMutation()
				.then(({ data }) => {
					if (
						!data?.createOrder ||
						(!data.createOrder.paymentUrl && !data.createOrder.errors?.length)
					)
						return onErrorMessage()

					if (data.createOrder.errors) {
						const error = data.createOrder.errors[0]
						if (error.code === CreateOrderErrorCode.NOT_ENOUGH_BALANCE) {
							setErrorMessage(t('error.notEnoughBalance_GENERATE'))
						} else if (error.code === CreateOrderErrorCode.INVALID_USERNAME) {
							setErrorMessage(t('error.invalidUsername_GENERATE'))
						} /* if (error.code === CreateOrderErrorCode.CREATING_ERROR)  */ else {
							onErrorMessage(error.message)
						}
						return
					}

					window.location.assign(data.createOrder.paymentUrl ?? '')
				})
				.catch((e) => {
					onErrorMessage(getErrorData(e).message)
				})
		}

		const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			setErrorMessage('')

			if (loading) return

			if (isUserStep) {
				// need to validate username
				if (checkUsername.isFreeUsername) {
					onUsernameFree()
				} else {
					checkUsername.check()
				}
			} else if (step === Step.paymentStep) {
				modalCopyAgreement.open()
			}
		}

		const price = getOrderPriceQuery.data?.getOrderPrice ?? 0

		return (
			<div className={cx('Component', 'container_fluid')}>
				<Section
					afterContent={
						!isUserStep && (
							<Button
								type='button'
								color='link'
								onClick={() => setStep(Step.usernameStep)}
								className={cx('BtnBack')}
							>
								{t('pageGenerateWallet.back')}
							</Button>
						)
					}
					beforeContent={<Steps currentStep={step} stepList={StepNumbers} />}
					header={t(`pageGenerateWallet.${Step[step]}.title`)}
				>
					{isDisabledFlow && (
						<FormDisabled>{t('pageGenerateWallet.disabled')}</FormDisabled>
					)}
					{!isDisabledFlow && (
						<form onSubmit={onSubmit} className={cx('Form')}>
							{step === Step.usernameStep && (
								<UsernameStep
									username={username}
									setUserName={setUserName}
									isFreeUserName={checkUsername.isFreeUsername}
									validateRule={validateRule}
								/>
							)}
							{step === Step.paymentStep && (
								<PaymentStep
									username={username}
									wallet={wallet.wallet!}
									agreeCopy={agreeCopy}
									setAgreeCopy={setAgreeCopy}
									agree={agree}
									setAgree={setAgree}
									finance={finance}
									setFinance={setFinance}
								/>
							)}

							<p className={cx('ErrorMessage')}>{errorMessage}</p>

							<Button
								className={cx(loading && 'BtnSubmit_loading')}
								color={errorOnGeneratingKeys ? 'red' : 'green'}
								disabled={disabled}
								type='submit'
							>
								{loading ? (
									<img src='/loader_green.gif' height={24} alt='' />
								) : isUserStep ? (
									errorOnGeneratingKeys ? (
										t('pageGenerateWallet.usernameStep.actions.generateError')
									) : checkUsername.isFreeUsername ? (
										t('pageGenerateWallet.usernameStep.actions.generate')
									) : (
										t('pageGenerateWallet.usernameStep.actions.validate')
									)
								) : finance ? (
									t('pageGenerateWallet.paymentStep.actions.pay', {
										amount: `${price} ${finance.currency}`,
									})
								) : (
									t('pageGenerateWallet.paymentStep.actions.choose')
								)}
							</Button>
						</form>
					)}
				</Section>
				<ModalCopyAgreement {...modalCopyAgreement} onSubmit={createWallet} />
			</div>
		)
	}
)

export default PageGenerateWallet
