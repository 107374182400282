import React, { memo, ReactNode } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

const Checkbox = memo(
	({
		label,
		setChecked,
		checked,
	}: {
		label: ReactNode
		checked: boolean
		setChecked: (v: boolean) => void
	}) => {
		return (
			<div onClick={() => setChecked(!checked)} className={cx('Checkbox')}>
				<p className={cx('CheckboxLabel')}>{label}</p>
				<span className={cx('Switcher', checked && 'Switcher_active')} />
			</div>
		)
	}
)

export default Checkbox
