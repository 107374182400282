export default {
    title: 'Компания',
    text: [
        'Реквизиты "IN-CTO HOLDINGS PTE. LTD."',
        '',
        'Регистрационный номер: 201710457E',
        'Юридический адрес: 9 Raffles Place, #26-01 Republic Plaza, Singapore, (048619)',
        'Фактический адрес: 9 Raffles Place, #26-01 Republic Plaza, Singapore, (048619)',
        'Почтовый адрес: 9 Raffles Place, #26-01 Republic Plaza, Singapore, (048619)',
        'Email: dveoservice@gmail.com',
        '',
        'Банковская информация:',
        '',
        'Наименование банка: Unibank OJSC',
        'Адрес банка: 5/1, Nothern ave, Yerevan, RA',
        'SWIFT: UNIJAM22',
        'Р/С: 24149001262202 (RUB)',
        'Р/С: 24149001262200 (USD)',
        'Р/С: 24149001262201 (EUR)',
    ],
}