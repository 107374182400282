import React, { useState } from 'react'
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg'
import { ReactComponent as IconCancel } from 'assets/icons/cancel.svg'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

export type Props = JSX.IntrinsicElements['input'] & {
	isActive?: boolean
	valid?: boolean | null
	classNameWrapper?: string
	rightControl?: JSX.Element
}

export default ({
	valid,
	isActive,
	classNameWrapper = '',
	rightControl,
	className = '',
	...props
}: Props) => {
	const [isFocused, setIsFocused] = useState(false)

	return (
		<div
			className={cx('Component', classNameWrapper, {
				Component_focus: isFocused || isActive,
				Component_error: valid === false,
				Component_success: valid === true,
			})}
		>
			<input
				{...props}
				className={cx('Input', className)}
				onBlur={(e) => {
					setIsFocused(false)
					;(props as any).onBlur?.(e)
				}}
				onFocus={(e) => {
					setIsFocused(true)
					;(props as any).onFocus?.(e)
				}}
			/>
			<div className={cx('RightControl')}>
				{rightControl}
				{valid === false && <IconCancel className={cx('Icon')} />}
				{valid === true && <IconSuccess className={cx('Icon')} />}
			</div>
		</div>
	)
}
