import React, { memo, Fragment } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
const cx = classNames.bind(styles)

const PageTerms = memo(() => {
	const [t, { language }] = useTranslation()
	return (
		<div className={cx('Component', 'container')}>
			<article className={cx('Content')}>
				<h2 className={cx('Title')}>{t('pageTerms.title')}</h2>

				{language === 'ru' && (
					<Fragment>
						<p>
							Компания IN-CTO HOLDINGS PTE. LTD., регистрационный номер
							201710457E, зарегистрированная по адресу 9 Raffles Place, #26-01
							Republic Plaza, Singapore, (048619), именуемая в дальнейшем
							Исполнитель, публикует для неограниченного круга лиц настоящую
							публичную оферту об оказании услуг (далее «Договор») с целью
							оказания услуг через принадлежащий ему интернет магазин
							«MYEOSWALLET», расположенный на доменном имени «
							<a href='https://myeoswallet.net/' target='_blank'>
								https://myeoswallet.net/
							</a>
							» (далее «Магазин»), для заключения Договора с Заказчиком
							дистанционным способом на изложенных ниже условиях.
						</p>
						<ol>
							<li>
								<h3>Термины и определения настоящей оферты.</h3>
								<ol>
									<li>
										Публичная оферта – текст настоящего документа, расположенный
										в сети Интернет, со всеми приложениями и дополнения к нему,
										доступный неограниченному кругу лиц по следующему
										интернет-адресу: «
										<a href='https://myeoswallet.net/terms' target='_blank'>
											https://myeoswallet.net/terms
										</a>
										»;
									</li>
									<li>
										«Акцепт Оферты» – совершение Заказчиком действий, указанных
										в настоящей Оферте, свидетельствующих о принятии данным
										лицом условий Оферты в полном объеме, в том числе,
										совершении действий по выполнению указанных в настоящей
										Оферте условий;
									</li>
									<li>
										Услуги – деятельность Исполнителя, связанная с созданием
										аккаунта в блокчейн сети EOS для Заказчика и/или техническим
										улучшением аккаунта Заказчика;
									</li>
									<li>
										Договор – договор оказания услуг, заключаемый между
										Исполнителем и Заказчиком в результате принятия настоящей
										оферты;
									</li>
									<li>
										Заказчик - совершеннолетнее дееспособное физическое лицо,
										индивидуальный предприниматель или юридическое лицо,
										заключивший Договор с Исполнителем в письменной электронной
										форме в результате осуществления действий, составляющие
										принятие настоящей публичной оферты (Акцепта Оферты) и, тем
										самым, получивший право получать Услуги Исполнителя и в
										полном объеме выполняющий свои обязательства по Договору.
									</li>
								</ol>
							</li>
							<li>
								<h3>Порядок принятия оферты.</h3>
								<ol>
									<li>
										Настоящая оферта считается должным образом принятой
										Заказчиком с момента произведения им действий по ее принятию
										в момент заказа услуги на сайте интернет-магазина,
										включающее в себя подтверждение декларации о принятии
										публичной оферты путем нажатия кнопки в момент заказа
										услуги;
									</li>
									<li>
										Фактом, подтверждающим принятие изложенных условий настоящей
										публичной оферты, и акцептом настоящей публичной оферты
										является заказ услуги;
									</li>
									<li>
										Принятие настоящей публичной оферты также составляет
										принятие Заказчиком политики конфиденциальности, текст
										которой является неотъемлемой частью настоящей оферты и
										приложен к ней в качестве Приложения 1, а также доступен по
										«
										<a href='https://myeoswallet.net/privacy' target='_blank'>
											https://myeoswallet.net/privacy
										</a>
										»;
									</li>
									<li>
										Настоящий договор действует до исполнения сторонами всех
										своих обязательств, предусмотренных настоящим договором.
									</li>
								</ol>
							</li>
							<li>
								<h3>Предмет договора.</h3>
								<ol>
									<li>
										Исполнитель обязуется оказывать Заказчику выбранную им
										Услугу.
									</li>
									<li>
										Заказчик обязуется оплачивать Услугу на условиях и в
										порядке, определенных настоящим Договором.
									</li>
								</ol>
							</li>
							<li>
								<h3>Условия договора.</h3>
								<ol>
									<li>
										Оплата услуг производится Заказчиком на основании
										выставленного Исполнителем счета. Условия оплаты услуг,
										указываются в заявке.
									</li>
									<li>Стоимость услуг указывается без НДС.</li>
									<li>
										Срок оказания услуги 24 часа с момента зачисления денежных
										средств на расчетный счет Исполнителя.
									</li>
									<li>
										Все расчеты по договору производятся в рублях РФ,
										американских долларах и евро.
									</li>
									<li>
										Заказчик вправе потребовать от Исполнителя возврат денежных
										средств за услугу, если она была не оказана или выполнена
										некачественно, соблюдя следующие условия:
									</li>
									<li>
										В течении 3-х календарных дней после оказания услуги
										Исполнителем, Заказчик направляет письменную претензию на
										электронную почту Исполнителя, указанную в реквизитах
										Компании на сайте 
										<a href='https://myeoswallet.net/company' target='_blank'>
											https://myeoswallet.net/company
										</a>
										.
									</li>
									<li>
										Исполнитель рассматривает претензию в течение 10 дней и дает
										письменный мотивированный ответ Заказчику.
									</li>
									<li>
										Применимым правом по настоящему Договору является Английское
										право.
									</li>
									<li>
										Стороны прикладывают максимальные усилия, чтобы устранить
										возникающие разногласия путем переговоров. Претензионный
										порядок досудебного урегулирования споров является для
										Сторон обязательным. Срок рассмотрения претензии составляет
										10 дней с момента ее получения.
									</li>
									<li>
										При не урегулировании в процессе переговоров спорных
										вопросов споры разрешаются в соответствии с
										законодательством Сингапура
									</li>
								</ol>
							</li>
						</ol>
					</Fragment>
				)}

				{language === 'en' && (
					<Fragment>
						<p>
							Company IN-CTO HOLDINGS PTE. LTD. registered under number
							201710457E at 9 Raffles Place, #26-01 Republic Plaza, Singapore,
							(048619), hereinafter referred to as the Contractor, publishes for
							an unlimited number of persons this public offer for the provision
							of services (hereinafter referred to as the "Agreement") for the
							purpose to provide services through its online store
							"MYEOSWALLET", located on the domain name "
							<a href='https://myeoswallet.net/' target='_blank'>
								https://myeoswallet.net/
							</a>
							" (Hereinafter referred to as the "Store"), for concluding an
							Agreement with the Customer remotely using the conditions set
							forth below.
						</p>

						<ol>
							<li>
								<h3>Terms and definitions of this offer.</h3>
								<ol>
									<li>
										Public offer - the text of this document, located on the
										Internet, with all applications and additions to it,
										available to an unlimited number of persons at the following
										Internet address: "
										<a href='https://myeoswallet.net/terms' target='_blank'>
											https://myeoswallet.net/terms
										</a>
										";
									</li>
									<li>
										“Offer Acceptance” - the Customer has completed the actions
										specified in this Offer, which indicate that the person has
										accepted the terms of the Offer in full volume, including
										the committing of actions to fulfill the conditions
										specified in this Offer;
									</li>
									<li>
										Services - the Contractor’s activities related to the
										account creation on the EOS blockchain for the Customer and
										/ or technical improvement of the Customer’s account;
									</li>
									<li>
										Agreement - a contract for the provision of services
										concluded between the Contractor and the Customer as a
										result of the adoption of this offer;
									</li>
									<li>
										The Customer - an adult legal capacity individual,
										individual entrepreneur or legal entity who entered into an
										Agreement with the Contractor in electronic form in writing
										as a result of the actions constituting the acceptance of
										this public offer (Offer Acceptance) and, thereby, having
										received the right to receive the Contractor's Services and
										performing their obligations under the Agreement in full
										volume.
									</li>
								</ol>
							</li>
							<li>
								<h3>Offer acceptance procedure.</h3>

								<ol>
									<li>
										This offer is considered to be duly accepted by the Customer
										from the moment he takes action to take it at the moment of
										ordering the service on the website of the online store,
										which includes confirmation of the declaration of acceptance
										of the public offer by pressing a button at the moment of
										ordering the service;
									</li>
									<li>
										The fact of acceptance of the stated conditions of this
										public offer and the acceptance of this public offer is the
										order of the service;
									</li>
									<li>
										The acceptance of this public offer also constitutes the
										acceptance by the Customer of a privacy policy, the text of
										which is an integral part of this offer and is attached to
										it as Application 1, and is also available at{' '}
										<a href='https://myeoswallet.net/privacy' target='_blank'>
											https://myeoswallet.net/privacy
										</a>
										;
									</li>
									<li>
										This agreement is valid until the parties fulfill all their
										obligations stipulated by this agreement.
									</li>
								</ol>
							</li>

							<li>
								<h3>Subject of the Agreement.</h3>

								<ol>
									<li>
										The Contractor agrees to provide Services chosen by the
										Customer.
									</li>
									<li>
										The Customer undertakes to pay for these Services under the
										conditions and according to the procedures defined by the
										provisions of this Agreement.
									</li>
								</ol>
							</li>

							<li>
								<h3>Agreement conditions.</h3>
								<ol>
									<li>
										Services payment is made by the Customer under the
										conditions of an invoice issued by the Contractor. Terms of
										payment for services are indicated in the application.
									</li>
									<li>The cost of Services does not include VAT</li>
									<li>
										The term for the provide of the service is 24 hours from the
										moment the funds are credited to the account of the
										Contractor.
									</li>
									<li>
										All payments under the Agreement are made in Russian Rubles,
										American Dollars and Euros.
									</li>
									<li>
										The Customer has the right to demand from the Contractor a
										refund of money for the service if it was not provided or
										performed poorly, observing the following conditions:
									</li>
									<li>
										The Customer sends a written claim to the Contractor’s email
										specified in the details of the Company on the website{' '}
										<a href='https://myeoswallet.net/company' target='_blank'>
											https://myeoswallet.net/company
										</a>
										.
									</li>
									<li>
										The Contractor considers the claim within 10 days and gives
										a written reasoned response to the Customer.
									</li>
									<li>
										Applicable law under this Agreement is the English law;
									</li>
									<li>
										The parties make every effort to resolve disputes through
										negotiations. The claim procedure for pre-trial settlement
										of disputes is binding on the Parties. The term for
										consideration of a claim is 10 days from the date of its
										receipt.
									</li>
									<li>
										Any dispute arising under this Agreement which is not
										settled by direct negotiations of the Parties may be settled
										in accordance with current legislation of the Great Britain
									</li>
								</ol>
							</li>
						</ol>
					</Fragment>
				)}
			</article>
		</div>
	)
})

export default PageTerms
