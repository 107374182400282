import React, { memo, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Wallet } from '.'
import { useTranslation, Trans } from 'react-i18next'
import useCopy from 'helpers/useCopy'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import Checkbox from 'components/UIKit/Checkbox'
import Param from 'components/UIKit/Param'
import Attention from 'components/UIKit/Attention'
import CurrencyField from 'components/CurrencyField'
import { Settings_finances } from 'apollo/__generated_types__/Settings'
const cx = classNames.bind(styles)

type Props = {
	username: string
	wallet: NonNullable<Wallet>
	finance: Settings_finances
	setFinance: (v: Settings_finances) => void
	agreeCopy: boolean
	setAgreeCopy: (v: boolean) => void
	agree: boolean
	setAgree: (v: boolean) => void
}

const PaymentStep = memo(
	({
		username,
		wallet,
		agreeCopy,
		setAgreeCopy,
		agree,
		setAgree,
		finance,
		setFinance,
	}: Props) => {
		const [t] = useTranslation()
		const copyText = useCopy()
		return (
			<Fragment>
				<Attention>{t('pageGenerateWallet.paymentStep.attention')}</Attention>

				<Param
					copy={copyText.copy}
					label={t('pageGenerateWallet.paymentStep.params.account')}
					value={username}
				/>
				<Param
					copy={copyText.copy}
					label={t('pageGenerateWallet.paymentStep.params.publicKey')}
					value={wallet.publicKey}
				/>
				<Param
					copy={copyText.copy}
					label={t('pageGenerateWallet.paymentStep.params.privateKey')}
					value={wallet.privateKey}
				/>

				<div className={cx('AgreeGroup')}>
					<Checkbox
						label={
							<b className={cx('AgreeCopy')}>
								{t('pageGenerateWallet.paymentStep.agreeSave')}
							</b>
						}
						checked={agreeCopy}
						setChecked={setAgreeCopy}
					/>
					<Checkbox
						label={
							<Trans i18nKey='pageGenerateWallet.paymentStep.agree'>
								<Link
									to='/terms'
									target='_blank'
									onClick={(e) => e.stopPropagation()}
								>
									.
								</Link>
							</Trans>
						}
						checked={agree}
						setChecked={setAgree}
					/>
				</div>

				<CurrencyField
					label={t('pageGenerateWallet.paymentStep.currency.label')}
					placeholder={t('pageGenerateWallet.paymentStep.currency.placeholder')}
					value={finance}
					onClickOption={setFinance}
				/>
				<div className={cx('Tooltip', copyText.copied && 'Tooltip_show')}>
					{t('global.copied')}
				</div>
			</Fragment>
		)
	}
)

export default PaymentStep
