import React, { memo } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import useDropdown from 'helpers/useDropdown'
import { LOCAL_STORAGE_LNG_KEY, Languages, LANGUAGES_MAP } from 'constants/'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconTriagle } from 'assets/icons/triagle.svg'
const cx = classNames.bind(styles)

const LanguageSwitcher = memo(() => {
	const dropdown = useDropdown()
	const [_, i18n] = useTranslation()
	const currentLanguageLabel = LANGUAGES_MAP[i18n.language as Languages]

	const changeLanguage = (lng: Languages) => {
		dropdown.toggle()
		i18n.changeLanguage(lng)
		localStorage.setItem(LOCAL_STORAGE_LNG_KEY, lng)
	}

	return (
		<div className={cx('Row', 'container_fluid')}>
			<div className={cx('Component')} ref={dropdown.ref}>
				<button
					type='button'
					onClick={dropdown.toggle}
					className={cx('Control', dropdown.open && 'Control_open')}
				>
					<span>
						{currentLanguageLabel}
						<IconTriagle />
					</span>
				</button>
				{dropdown.open && (
					<ul className={cx('Content')}>
						{Object.entries(LANGUAGES_MAP).map(([lng, label]) => (
							<li key={lng}>
								<button
									type='button'
									onClick={() => changeLanguage(lng as Languages)}
									className={cx('LngBtn')}
								>
									{label}
								</button>
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	)
})

export default LanguageSwitcher
