import React from 'react'
import Modal, { ModalProps, useModalContext } from 'components/Modal'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as Icon } from 'assets/icons/warning.svg'
import Button from 'components/UIKit/Button'
const cx = classNames.bind(styles)

type ModalCopyAgreementProps = {
    onSubmit(): void
}

const ModalCopyAgreement = ({
	isOpen,
	close,
	...props
}: ModalCopyAgreementProps & ModalProps) => {
	return (
		<Modal isOpen={isOpen} close={close}>
			<Main {...props} />
		</Modal>
	)
}

const Main = ({ onSubmit }: ModalCopyAgreementProps) => {
	const { close } = useModalContext()
	const [t] = useTranslation()
	return (
		<div className={cx('Component')}>
			<Icon className={cx('Icon')} />
			<p className={cx('Message')}>{t('modalCopyAgreement.message')}</p>

			<div className={cx('Actions')}>
				<Button onClick={onSubmit} type='button' color='red'>
					{t('modalCopyAgreement.submit')}
				</Button>
				<button onClick={close} className={cx('BtnCancel')} type='button' color='red'>
					{t('modalCopyAgreement.cancel')}
				</button>
			</div>
		</div>
	)
}

export default ModalCopyAgreement
