import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconCancel } from 'assets/icons/cancel.svg'
import Button from 'components/UIKit/Button'
import { useRootContext, ErrorCode } from 'helpers/rootContext'
import { useLocation } from 'react-router-dom'
import Section from 'components/Section'
const cx = classNames.bind(styles)

type Props = {
	status?: NonNullable<ErrorCode>
}

const PageError = ({ status = 404 }: Props) => {
	const [t] = useTranslation()
	const isFirstRender = useRef(false)
	const { pathname } = useLocation()
	const { setErrorStatus } = useRootContext()
	const resetError = () => {
		setErrorStatus(null)
	}

	const statusDigits: any[] | null = status ? status.toString().split('') : null

	useEffect(() => {
		if (!isFirstRender.current) {
			isFirstRender.current = true
		} else {
			resetError()
		}
	}, [pathname])


	if (statusDigits) {
		statusDigits[1] = <IconCancel />
	}

	return (
		<div className={cx('Component', 'container_fluid')}>
			<Section className={cx('Content')}>
				<div className={cx('Status')}>{statusDigits}</div>
				<p>{t(`pageError.${status}`)}</p>
				<Button color='green' tag='link' to='/' onClick={resetError}>
					{t('pageError.btnHome')}
				</Button>
			</Section>
		</div>
	)
}

export default PageError
