import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { ReactComponent as LogoBlue } from 'assets/logo_blue.svg'
import { ReactComponent as LogoGreen } from 'assets/logo_green.svg'
import DropdownForm from './DropdownForm'
import { URL_MAP } from 'containers/App'
import { useRootContext } from 'helpers/rootContext'
const cx = classNames.bind(styles)

const Header = memo(() => {
	const { pathname } = useLocation()
	const { errorStatus } = useRootContext()
	if (pathname === '/' && !errorStatus) return null

	const isGreenLogoNeed = pathname.includes(URL_MAP.resource)

	return (
		<header className={cx('Header', 'container_fluid')}>
			<Link to='/' className={cx('Logo')}>
				{isGreenLogoNeed ? <LogoGreen /> : <LogoBlue />} myeoswallet
			</Link>
			<DropdownForm />
		</header>
	)
})

export default Header
