import React, { PropsWithChildren } from 'react'
import VersionController from 'containers/Helpers/VersionController'
import styles from './index.module.scss'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import Button from 'components/UIKit/Button'
import Section from 'components/Section'
const cx = classNames.bind(styles)

const WithVersionController = ({ children }: PropsWithChildren<{}>) => {
	const [t] = useTranslation()

	return (
		<VersionController
			isEnabled={process.env.NODE_ENV === 'production'}
			url={process.env.REACT_APP_VERSION_CONTROLLER_URL!}
			// we not need to do smth because we have not cache
			onUpdateFound={() => {}}
			reconnectingLimit={10}
			reconnectingTimeout={1500}
			version={process.env.REACT_APP_VERSION!}
			LoadingScreen={<div className='preloader vc-preloader' />}
			ReconnectingScreen={
				<div className={cx('Reconnecting', 'Reconnecting_blur')}>
					<img
						width={120}
						height={120}
						alt='Loading...'
						src={process.env.PUBLIC_URL + '/preloader.gif'}
					/>
					<p className='h3 mt-4'>{t('versionController.reconnecting')}</p>
				</div>
			}
			UpdateScreen={({ onClick, updating }) => {
				return (
					<div className={cx('UpdateScreen', 'container')}>
						<Section >
							<p className='h3 mb-4'>{t('versionController.text')}</p>
							<Button
								onClick={updating ? undefined : onClick}
								color='green'
								disabled={updating}
							>
								{updating && (
									<img
										style={{ marginRight: 8 }}
										width={20}
										height={20}
										alt='Loading...'
										src={process.env.PUBLIC_URL + '/loader_green.gif'}
									/>
								)}
								{t('versionController.btn')}
							</Button>
						</Section>
					</div>
				)
			}}
		>
			{children}
		</VersionController>
	)
}

export default WithVersionController
