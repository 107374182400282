import { useState, useEffect } from 'react'
import * as clipboard from 'clipboard-polyfill'

export default () => {
	const [copied, setCopied] = useState(false)

	useEffect(() => {
		const tid = setTimeout(() => {
			setCopied(false)
		}, 2000)

		return () => clearTimeout(tid)
	}, [copied])

	return {
		copied,
		copy: (s: string) => {
			setCopied(false)
			clipboard
				.writeText(s)
				.then(() => setCopied(true))
				.catch(() => setCopied(false))
		},
	}
}
